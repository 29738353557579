import { gql } from "@apollo/client";

export const REVOKE_PAL_FROM_VISIT = gql`
  mutation revokePalFromVisit($id: ID!, $input: CancelVisitInput!) {
    revokePalFromVisit: cancelVisit(id: $id, input: $input) {
      data {
        id
        state
        pal {
          data {
            id
            fullName
          }
        }
      }
    }
  }
`;
