import { Formik } from "formik";
import omit from "lodash/omit";
import React from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { Pal, PapaInput, useUpdatePapaPreferencesMutation } from "generated/types";
import { Gender } from "generated/types";
import { Papa } from "generated/types";
import PreferencesForm from "pages/Papas/List/CreatePapa/PreferencesForm";
import { nonNull } from "utils/nonNull";

import { FormValues, schema } from "./schema";
import { useHasGenderPreferenceMismatchConfirm } from "./useHasGenderPreferenceMismatchConfirm";

interface Props {
  papa: Papa;
  onEditFinish?: () => void;
}

export const mapPalsToOptions = (pals: Pal[] = []) => {
  return pals.map((pal) => ({
    label: pal.fullName ?? "",
    value: pal.id ?? "",
  }));
};

export const EditPreferenceForm: React.FC<Props> = ({ papa, onEditFinish = () => {} }) => {
  const genderMismatchConfirm = useHasGenderPreferenceMismatchConfirm();
  const [updatePreferences] = useUpdatePapaPreferencesMutation();

  const handleSubmit = async (input: FormValues) => {
    const { genderPreference, favoritePalIds, ...restInput } = omit(input, ["palName"]);

    if (
      !(await genderMismatchConfirm({
        papaId: papa?.id ?? "",
        favoritePalIds: favoritePalIds ?? [],
        gender: genderPreference,
      }))
    ) {
      return;
    }

    try {
      const { data } = await updatePreferences({
        variables: {
          id: papa.id ?? "",
          input: {
            ...restInput,
            favoritePalIds,
            removeFavoritePals: favoritePalIds?.length === 0,
            genderPreference: genderPreference === Gender.Diverse ? null : genderPreference,
          } as PapaInput,
        },
      });

      if (data?.updatePapa?.data) {
        toast.success("Papa preferences were edited with success!");
        onEditFinish();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const favoritePalIds = nonNull(papa?.favoritePals?.data).map(({ id }) => id ?? "");

  const favoritePals = mapPalsToOptions(nonNull(papa?.favoritePals?.data));

  const initialValues: FormValues = {
    genderPreference: papa.genderPreference ?? Gender.Diverse,
    languagePreference: papa.languagePreference || null,
    vehiclePreference: papa.vehiclePreference || null,
    favoritePalIds: favoritePalIds || [],
    defaultToFavoritePalsOnly: papa?.defaultToFavoritePalsOnly ?? false,
    genderPreferenceIsFlexible:
      papa?.genderPreferenceIsFlexible === undefined ? null : papa?.genderPreferenceIsFlexible,
    genderPreferenceWasAsked: !!papa?.genderPreferenceWasAsked,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ModalStyledForm>
        <ModalContainer>
          <PreferencesForm
            favoritePals={favoritePals}
            genderPreferenceLocked={papa?.genderPreferenceLocked}
          />
        </ModalContainer>

        <ModalFooter>
          <Button variant="secondary" onClick={onEditFinish}>
            Close
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </ModalFooter>
      </ModalStyledForm>
    </Formik>
  );
};
