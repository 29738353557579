import { gql } from "@apollo/client";

export const GET_PAPA_OVERVIEW = gql`
  query PapaOverview($papaId: ID!) {
    papa(id: $papaId) {
      data {
        id
        isTestPapa
        memberId
        fullName
        business {
          data {
            id
            name
            inboundMemberSupportNumber
          }
        }
        caregiver {
          data {
            id
            fullName
            birthDate
            business {
              data {
                id
                name
              }
            }
            account {
              data {
                phoneNumber
              }
            }
          }
        }
        eligibleLife {
          data {
            planType
            pcpName
            planId
          }
        }
        dataVisibility {
          concealed
          masked
        }
        preferredContactMethod
        fixedPhoneNumber
        phoneNumber
        language
        gender
        genderText
        email
        birthDate
        account {
          data {
            id
            fullName
            type
          }
        }
        relationship
        status
        automatedCallOptIn
        marketingConsent
        covidVaccinated
        currentBusinessPolicy {
          data {
            careConciergeEnabled
            endsAt
            startsAt
          }
        }
        resourceBudget {
          data {
            limitingTimeAllocation {
              data {
                intervalUnit
                numberOfMinutes
                remainingMinutes
              }
            }
          }
        }
        messageServiceAuthorizations {
          data {
            id
            consentStatus
            agreement
            consentAgreementSigned
            initialMessageDelivered
            autoEnrolled
          }
        }
        authorizedUser {
          data {
            id
            firstName
            lastName
            relationship
          }
        }
        papaSuspension {
          data {
            id
            suspendedUntil
          }
        }
        allotmentPeriods {
          data {
            id
            startDate
            endDate
            allottedMinutes
            usedMinutes
            availableMinutes
            creditedMinutes
            pendingMinutes
            businessPolicy {
              data {
                id
              }
            }
            creditGrants {
              data {
                id
                grantedBy {
                  data {
                    id
                    fullName
                  }
                }
                grantedAt
                minutes
                notes
                papaId
                ticketId
                visitId
                creditReason {
                  data {
                    id
                    reasonName
                    reasonCategory
                  }
                }
              }
            }
          }
        }
        homeLocation {
          data {
            timezone
          }
        }
      }
    }
  }
`;

export const GET_PAPA_DETAILS = gql`
  query PapaDetails($id: ID!) {
    papa(id: $id) {
      data {
        id
        isTestPapa
        isEligible
        genderPreferenceIsFlexible
        genderPreferenceWasAsked
        eligibleLife {
          data {
            id
          }
        }
        homeLocation {
          data {
            id
            nickname
            address
            address2
            city
            countryIso
            lat
            lng
            state
            zipcode
            timezone
          }
        }
        caregiver {
          data {
            id
            business {
              data {
                currentBusinessPolicy {
                  data {
                    id
                    allowInPersonVisits
                    allowUberForTransportation
                    allowVirtualVisits
                    careConciergeEnabled
                    endsAt
                    intervalUnit
                    maxMinutesPerVisit
                    inPersonAllowedTasks {
                      data {
                        id
                        name
                      }
                    }
                    startsAt
                    virtualAllowedTasks {
                      data {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
        description
        preferredContactMethod
        stillDrives
        accountId
        defaultToFavoritePalsOnly
        fullName
        dataVisibility {
          concealed
          masked
        }
        account {
          data {
            id
          }
        }
        warnings {
          data
        }
        status
        requirements {
          data
        }
        papaSpecialConsiderations {
          data {
            value
            specialConsideration {
              data {
                name
              }
            }
          }
        }
        insertedAt
        updatedAt
        enrolledBy {
          data {
            id
            fullName
          }
        }
        genderPreference
        genderPreferenceLocked
        languagePreference
        livingSituation
        vehiclePreference
        favoritePals {
          data {
            id
            fullName
          }
        }
        blockedPals {
          data {
            id
            fullName
          }
        }
        biographyQuestions {
          question
          answer
        }
        locations(filter: { deletedAt: { eq: null } }) {
          data {
            id
            nickname
            address
            address2
            city
            countryIso
            lat
            lng
            state
            zipcode
            timezone
          }
        }
        homeLocation {
          data {
            id
            countryIso
            lat
            lng
            zipcode
            address
            address2
            nickname
            timezone

            # for time recommendation
            state
            city
            county

            supportOperatingHours {
              startTime
              endTime
            }
          }
        }
        currentBusinessPolicy {
          data {
            id
            allowInPersonVisits
            allowUberForTransportation
            allowVirtualVisits
            careConciergeEnabled
            endsAt
            intervalUnit
            maxMinutesPerVisit
            inPersonAllowedTasks {
              data {
                id
                name
              }
            }
            startsAt
            virtualAllowedTasks {
              data {
                id
                name
              }
            }
          }
        }
        business {
          data {
            id
            name
            type
            currentBusinessPolicy {
              data {
                id
                warmTransfer
                warmTransferPhoneNumber
                hasUnlimitedMinutes
              }
            }
          }
        }
        virtualVisitsOnly
        messageServiceAuthorizations {
          data {
            consentStatus
            consentAgreementSigned
            autoEnrolled
          }
        }
      }
    }
  }
`;

export const GET_PAPA_STATUS_LOGS = gql`
  query PapaStatusLogs($id: ID!) {
    papa(id: $id) {
      data {
        statusLog {
          data {
            id
            newState
            comment
            insertedAt
            account {
              data {
                id
                fullName
              }
            }
          }
          pagination {
            limit
            page
            totalCount
            totalPages
          }
        }
      }
    }
  }
`;
