import { useQuery } from "@apollo/client";
import { useField } from "formik";
import React, { useEffect } from "react";
import styled from "styled-components";

import Form from "components/Form";
import { Loader } from "components/Loader";
import Select from "components/Select";
import SwitchButton from "components/SwitchButton";
import { intervalOptions } from "constants/business-policy";

import { GET_BUSINESS_POLICY_UNLIMITED_MINUTES } from "./gql";

type UnlimitedHoursAmount = {
  businessPolicyUnlimitedMinutesAmount: number;
};

export const HourAllotment = () => {
  const { data, loading: loadingUnlimitedHourData } = useQuery<UnlimitedHoursAmount>(
    GET_BUSINESS_POLICY_UNLIMITED_MINUTES
  );
  const unlimitedHoursAmount = Math.round(data?.businessPolicyUnlimitedMinutesAmount! / 60);
  const [{ value: numberOfHours }, { initialValue: initialNumberOfHours }, numberOfHoursHelper] =
    useField<number>("numberOfHours");
  const [{ value: hasUnlimitedMinutes }, ,] = useField<boolean>("hasUnlimitedMinutes");

  useEffect(() => {
    if (hasUnlimitedMinutes) {
      /**
       * When a business only allows DHL visits we want to set the number of hours allotted
       * to be unlimited. The value itself is more or less a constant retrieved from the
       * backend that's just a very large number (10,000 hours as of writing this; which,
       * happens to be more than the number of hours in a year).
       * */
      if (unlimitedHoursAmount && numberOfHours !== unlimitedHoursAmount) {
        numberOfHoursHelper.setValue(unlimitedHoursAmount);
      }
    } else if (unlimitedHoursAmount && initialNumberOfHours) {
      if (numberOfHours === unlimitedHoursAmount && initialNumberOfHours === unlimitedHoursAmount) {
        numberOfHoursHelper.setValue(10);
      } else if (numberOfHours === unlimitedHoursAmount) {
        numberOfHoursHelper.setValue(initialNumberOfHours);
      }
    }
  }, [
    numberOfHours,
    hasUnlimitedMinutes,
    numberOfHoursHelper,
    initialNumberOfHours,
    unlimitedHoursAmount,
  ]);

  if (loadingUnlimitedHourData) return <Loader />;

  return (
    <>
      <Form.Group>
        <Form.Label>Billable Hour Allotment</Form.Label>
        <BillableHourAllotment>
          <div>
            <Form.Input
              disabled={hasUnlimitedMinutes}
              type="number"
              name="numberOfHours"
              className="hours-input"
            />
          </div>
          <HoursPer>hour(s) per</HoursPer>
          <Select
            aria-label="interval"
            name="intervalUnit"
            className="interval-selector"
            options={intervalOptions}
            isSearchable={false}
          />
        </BillableHourAllotment>
        <DhlOnlySwitchWrapper>
          <SwitchButton name="hasUnlimitedMinutes" label="Has unlimited hours" />
        </DhlOnlySwitchWrapper>
      </Form.Group>
    </>
  );
};

const DhlOnlySwitchWrapper = styled.div`
  margin-top: 1rem;
`;

const BillableHourAllotment = styled.div`
  display: flex;
  align-items: center;

  .hours-input {
    width: 8rem;
    input {
      width: 100%;
    }
  }

  .interval-selector {
    min-width: 8.5rem;
    width: 8.5rem;
  }
`;

const HoursPer = styled.div`
  font-style: italic;
  flex-shrink: 0;
  padding: 0 1rem;
`;

export default HourAllotment;
