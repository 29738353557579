import { InfoCircle } from "@styled-icons/bootstrap/InfoCircle";
import { Copy } from "@styled-icons/boxicons-regular/Copy";
import { Formik } from "formik";
import moment from "moment-timezone";
import React, { FunctionComponent, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import Conditional from "components/Conditional";
import { FormFooter, StyledForm } from "components/EditBio/styles";
import Form from "components/Form";
import { InputContainer, StyledInput } from "components/Form/FormInput";
import InputDateRange from "components/InputDateRange";
import { Loader } from "components/Loader";
import ConfirmModal from "components/Modal/ConfirmModal";
import { ModalContainer } from "components/Modal/Modal.styles";
import QueryErrors from "components/QueryErrors";
import Select from "components/Select";
import { AMERICA_NEW_YORK } from "constants/date";
import { virtualVisitTasks } from "constants/tasks";
import {
  BusinessPolicy,
  GetBusinessPolicyQuery,
  Maybe,
  useUpdateBusinessPolicyMutation,
} from "generated/types";
import { useAssessmentOptions } from "hooks/useAssessmentOptions";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import BusinessPolicySwitches from "modals/BusinessPolicyModal/BusinessPolicySwitches";
import HourAllotment from "modals/BusinessPolicyModal/HourAllotment";
import TaskSelect from "modals/BusinessPolicyModal/TaskSelect";
import { useBillingFormulaOptions } from "modals/BusinessPolicyModal/useBillingFormulaOptions";
import { useTaskSelectOptions } from "modals/BusinessPolicyModal/useTaskSelectOptions";
import { formatDateTime } from "utils/helpers/formatters";

import { Schema, schema } from "../modals/schema";

interface Props {
  accountId: string;
  businessId: string;
  businessPolicyId: string;
  businessPolicyData: GetBusinessPolicyQuery | undefined;
  businessPoliciesLoading: boolean;
  businessPoliciesError: any | undefined;
}

const EditBusinessPolicyForm: FunctionComponent<Props> = ({
  accountId,
  businessId,
  businessPolicyId,
  businessPolicyData,
  businessPoliciesLoading,
  businessPoliciesError,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState<Schema>();

  const history = useHistory();

  const { singularEntityName, capitalized, kebabCased } = useCallItClientProgramOrBusiness();

  const { options, loading: tasksLoading, error: tasksError } = useTaskSelectOptions();

  const [updateBusinessPolicy] = useUpdateBusinessPolicyMutation();

  const businessPolicies = useMemo(
    () => businessPolicyData?.businessPolicy?.data?.account?.data?.businessPolicies?.data || [],
    [businessPolicyData]
  );

  const error = tasksError || businessPoliciesError;
  const currentBusinessPolicy = businessPolicyData?.businessPolicy?.data;

  const currentStartsAt = currentBusinessPolicy?.startsAt
    ? moment.tz(currentBusinessPolicy.startsAt, AMERICA_NEW_YORK)
    : null;

  const currentEndsAt = currentBusinessPolicy?.endsAt
    ? moment.tz(currentBusinessPolicy.endsAt, AMERICA_NEW_YORK)
    : null;

  const maxTimeRule = currentBusinessPolicy?.maxTimeRules?.data?.[0];
  const numberOfHours = maxTimeRule?.numberOfMinutes
    ? Math.round(maxTimeRule.numberOfMinutes / 60)
    : 10;

  const initialValues = {
    numberOfHours: numberOfHours,
    dateRange: {
      startDate: currentStartsAt,
      endDate: currentEndsAt,
    },
    inPersonDisallowedTaskIds: currentBusinessPolicy?.inPersonDisallowedTasks?.data?.length
      ? currentBusinessPolicy.inPersonDisallowedTasks.data.map((task) => task?.id as string)
      : [],
    virtualDisallowedTaskIds: currentBusinessPolicy?.virtualDisallowedTasks?.data?.length
      ? currentBusinessPolicy.virtualDisallowedTasks.data.map((task) => task?.id as string)
      : [],
    allowInPersonVisits: currentBusinessPolicy?.allowInPersonVisits ?? true,
    allowVirtualVisits: currentBusinessPolicy?.allowVirtualVisits ?? true,
    billingFormulaId: currentBusinessPolicy?.billingFormula?.data?.id ?? "",
    lonelinessCompliant: currentBusinessPolicy?.lonelinessCompliant ?? false,
    palCovidVaccination: currentBusinessPolicy?.palCovidVaccination ?? false,
    papaCovidVaccination: currentBusinessPolicy?.papaCovidVaccination ?? false,
    allowUberForTransportation: currentBusinessPolicy?.allowUberForTransportation ?? false,
    careConciergeEnabled: currentBusinessPolicy?.careConciergeEnabled ?? false,
    paysForCareConcierge: currentBusinessPolicy?.paysForCareConcierge ?? null,
    maxMinutesPerVisit: currentBusinessPolicy?.maxMinutesPerVisit ?? null,
    hiddenAssessments:
      currentBusinessPolicy?.hiddenAssessments?.data?.map(({ surveyId }: any) => surveyId) ?? [],
    intervalUnit: maxTimeRule?.intervalUnit,
    warmTransfer: currentBusinessPolicy?.warmTransfer ?? false,
    warmTransferPhoneNumber: currentBusinessPolicy?.warmTransferPhoneNumber ?? "",
    maxVisitDistanceMi: currentBusinessPolicy?.maxVisitDistanceMi ?? null,
    maxScheduledVisitsPerDay: currentBusinessPolicy?.maxScheduledVisitsPerDay ?? null,
    hasUnlimitedMinutes: currentBusinessPolicy?.hasUnlimitedMinutes ?? false,
  };

  const getVirtualOptions = () =>
    options?.filter(({ label }) => virtualVisitTasks.includes(label)) || [];

  const intermediateSubmit = async (incomingformData: Schema) => {
    setFormData(incomingformData);

    const {
      dateRange: { endDate },
    } = incomingformData!;

    if (isDateEarlier(endDate, currentEndsAt)) {
      setIsOpen(true);
    } else {
      handleSubmit(incomingformData);
    }
  };

  const handleSubmit = async (incomingformData: Schema) => {
    const {
      numberOfHours,
      intervalUnit,
      dateRange: { endDate, startDate },
      inPersonDisallowedTaskIds,
      virtualDisallowedTaskIds,
      allowInPersonVisits,
      maxMinutesPerVisit,
      hiddenAssessments,
      maxVisitDistanceMi,
      maxScheduledVisitsPerDay,
      ...businessPolicyInput
    } = incomingformData!;

    const allTaskIds = options?.map(({ value }) => value) || [];
    const virtualTaskIds = getVirtualOptions().map(({ value }) => value);

    const maxTimeRuleInput = {
      businessPolicyId,
      numberOfMinutes: numberOfHours * 60,
      intervalUnit: intervalUnit,
    };

    const startDateWithTime = datesHaveChanged(startDate, currentStartsAt)
      ? beginningOfDay(startDate, AMERICA_NEW_YORK)?.toISOString()
      : startDate?.toISOString();

    const endDateWithTime = datesHaveChanged(endDate, currentEndsAt)
      ? endOfDay(endDate, AMERICA_NEW_YORK)?.toISOString()
      : endDate?.toISOString();

    setIsOpen(false);

    try {
      const { data } = await updateBusinessPolicy({
        variables: {
          businessPolicyId,
          input: {
            accountId,
            allowInPersonVisits,
            ...businessPolicyInput,
            maxMinutesPerVisit: maxMinutesPerVisit ? +maxMinutesPerVisit : null,
            startsAt: startDateWithTime,
            endsAt: endDateWithTime,
            inPersonAllowedTaskIds: inPersonDisallowedTaskIds?.length
              ? allTaskIds.filter((id) => !inPersonDisallowedTaskIds.includes(id))
              : allTaskIds,
            inPersonDisallowedTaskIds,
            virtualAllowedTaskIds: virtualDisallowedTaskIds?.length
              ? virtualTaskIds.filter((id) => !virtualDisallowedTaskIds.includes(id))
              : virtualTaskIds,
            virtualDisallowedTaskIds,
            hiddenSurveyIds: hiddenAssessments,
            numberOfMinutes: maxTimeRuleInput.numberOfMinutes,
            intervalUnit: maxTimeRuleInput.intervalUnit,
            maxVisitDistanceMi: maxVisitDistanceMi ? +maxVisitDistanceMi : null,
            maxScheduledVisitsPerDay: maxScheduledVisitsPerDay ? +maxScheduledVisitsPerDay : null,
          },
        },
      });

      if (!data?.updateBusinessPolicy?.data?.id) {
        throw new Error("Something is wrong");
      } else {
        toast.success(`${singularEntityName} Policy was updated with success!`);
        history.push(
          `/${kebabCased.pluralEntityName}/${businessId}/${kebabCased.singularEntityName}-policies/${businessPolicyId}`
        );
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  if (error)
    return (
      <ErrorContainer>
        <ModalContainer>
          <QueryErrors error={error} />
        </ModalContainer>
        <FormFooter>
          <Button variant="secondary">Close</Button>
        </FormFooter>
      </ErrorContainer>
    );

  return (
    <Loader isLoading={tasksLoading || businessPoliciesLoading}>
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={intermediateSubmit}
        >
          <StyledForm>
            <ModalContainer>
              <BusinessPolicyID id={businessPolicyId}></BusinessPolicyID>
              <StartsAtEndsAtRange
                businessPolicies={businessPolicies}
                message={`${capitalized.singularEntityName} already has ${singularEntityName} policies:`}
                title={`${capitalized.singularEntityName} rules validity period`}
              />
              <HourAllotment />
              <BillingFormula />
              <InPersonDisallowedTasks options={options} />
              <VirtualDisallowedTasks getVirtualOptions={getVirtualOptions} />
              <HiddenAssessments />
              <MaxMinutesPerVisit />
              <MaxVisitDistanceMi />
              <MaxScheduledVisitsPerDay />
              <BusinessPolicySwitches />
            </ModalContainer>
            <Footer
              onClose={() => {
                history.push(
                  `/${kebabCased.pluralEntityName}/${businessId}/${kebabCased.singularEntityName}-policies`
                );
              }}
            />
          </StyledForm>
        </Formik>
        <ConfirmModal
          header={"Are you sure you want to shorten this business policy?"}
          isOpen={isOpen}
          onCancel={() => {
            setIsOpen(false);
          }}
          onConfirm={() => handleSubmit(formData!)}
        >
          <p>Doing so will terminate all visits scheduled to occur after the new end date.</p>
        </ConfirmModal>
      </>
    </Loader>
  );
};

const datesHaveChanged = (firstDate: moment.Moment | null, secondDate: moment.Moment | null) => {
  return firstDate?.toISOString() !== secondDate?.toISOString();
};

const isDateEarlier = (firstDate: moment.Moment | null, secondDate: moment.Moment | null) => {
  return firstDate && secondDate && firstDate.toISOString() < secondDate.toISOString();
};

const beginningOfDay = (date: moment.Moment | null, timezone: string) => {
  if (date === null) {
    return null;
  }
  return date.tz(timezone).set({ hour: 0, minute: 0, second: 0 });
};

const endOfDay = (date: moment.Moment | null, timezone: string) => {
  if (date === null) {
    return null;
  }

  return date.tz(timezone).set({ hour: 23, minute: 59, second: 0 });
};

const BusinessPolicyID = ({ id }: { id: string }) => {
  const onCopyClick = () => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        toast.success("Copied business policy id!");
      })
      .catch((err) => {
        toast.error("Failed to copy business policy id");
      });
  };

  return (
    <Form.Group>
      <Form.Label>Business Policy ID</Form.Label>
      <InputContainer maxWidth="20rem">
        <StyledInput readOnly value={id} />
        <StyledCopy size={40} onClick={onCopyClick}></StyledCopy>
      </InputContainer>
    </Form.Group>
  );
};

const StartsAtEndsAtRange = ({
  title,
  businessPolicies,
  message,
}: {
  title: string;
  businessPolicies: Maybe<Array<Maybe<BusinessPolicy>>>;
  message: string;
}) => {
  const [isOtherPolicyInfoVisible, setIsOtherPolicyInfoVisible] = useState(false);
  return (
    <Form.Group>
      <Form.Label>
        {`${title}`}
        {(businessPolicies?.length ?? 0) > 0 && (
          <StyledInfoCircle
            size={16}
            onClick={() => setIsOtherPolicyInfoVisible(!isOtherPolicyInfoVisible)}
          />
        )}
      </Form.Label>
      <Conditional show={isOtherPolicyInfoVisible}>
        <OtherPoliciesInfo businessPolicies={businessPolicies} message={message} />
      </Conditional>

      <InputDateRange
        id="dateRange"
        name="dateRange"
        isOutsideRange={() => false}
        minimumNights={0}
      />
    </Form.Group>
  );
};

const OtherPoliciesInfo = ({
  businessPolicies,
  message,
}: {
  businessPolicies: Maybe<Array<Maybe<BusinessPolicy>>>;
  message: string;
}) => {
  return (
    <BusinessPoliciesInfo>
      <small>{message}</small>
      <ul>
        {businessPolicies?.map((businessPolicy) => (
          <li key={businessPolicy?.startsAt}>
            <small>
              From {formatDateTime(businessPolicy?.startsAt, { format: "L" })} to{" "}
              {formatDateTime(businessPolicy?.endsAt, { format: "L" })}
            </small>
          </li>
        ))}
      </ul>
    </BusinessPoliciesInfo>
  );
};

const BillingFormula = () => {
  const { formulaOptions } = useBillingFormulaOptions();
  return (
    <Form.Group>
      <Form.Label>Billing Formula</Form.Label>
      <Select name="billingFormulaId" options={formulaOptions} />
    </Form.Group>
  );
};

const InPersonDisallowedTasks = ({
  options,
}: {
  options: Array<{ label: string; value: string }>;
}) => {
  return (
    <Form.Group>
      <Form.Label>Tasks not permitted - in person</Form.Label>
      <TaskSelect name="inPersonDisallowedTaskIds" options={options} />
    </Form.Group>
  );
};

const VirtualDisallowedTasks = ({
  getVirtualOptions,
}: {
  getVirtualOptions: () => Array<{ label: string; value: string }>;
}) => {
  return (
    <Form.Group>
      <Form.Label>Tasks not permitted - virtual</Form.Label>
      <TaskSelect
        name="virtualDisallowedTaskIds"
        options={getVirtualOptions()}
        menuPlacement="top"
      />
    </Form.Group>
  );
};

const HiddenAssessments = () => {
  const { assessmentOptions } = useAssessmentOptions();

  return (
    <Form.Group>
      <Form.Label>Hidden assessments (invisible to agents)</Form.Label>
      <Select name="hiddenAssessments" options={assessmentOptions} isMulti />
    </Form.Group>
  );
};

const MaxMinutesPerVisit = () => {
  return (
    <Form.Group>
      <Form.Label>Max minutes per visit</Form.Label>
      <Form.Input name="maxMinutesPerVisit" />
    </Form.Group>
  );
};

const MaxVisitDistanceMi = () => {
  return (
    <Form.Group>
      <Form.Label>Max visit distance (miles; optional)</Form.Label>
      <Form.Input
        type="number"
        min="0"
        step="1"
        name="maxVisitDistanceMi"
        aria-label="maxVisitDistanceMi"
      />
    </Form.Group>
  );
};

const MaxScheduledVisitsPerDay = () => {
  return (
    <Form.Group>
      <Form.Label>Max scheduled visits per day (optional)</Form.Label>
      <Form.Input
        type="number"
        min="1"
        step="1"
        name="maxScheduledVisitsPerDay"
        aria-label="maxScheduledVisitsPerDay"
      />
    </Form.Group>
  );
};

const Footer = ({ onClose }: { onClose: () => void }) => {
  return (
    <FormFooter>
      <Button variant="secondary" onClick={onClose}>
        Back
      </Button>
      <Form.SubmitButton>Save</Form.SubmitButton>
    </FormFooter>
  );
};

const ErrorContainer = styled.div`
  width: 100%;
`;

const StyledInfoCircle = styled(InfoCircle)`
  color: #0d2973;
  margin-left: 0.5rem;
  cursor: pointer;
`;

const BusinessPoliciesInfo = styled.div`
  background: #0d2973;
  color: white;
  padding: 0 0.5rem;
  border-radius: 4px;
  width: 16rem;
  margin-bottom: 0.5rem;
  ul {
    margin: 0;
    padding: 0 0 0 1rem;
  }
`;

const StyledCopy = styled(Copy)`
  cursor: pointer;
`;

export default EditBusinessPolicyForm;
