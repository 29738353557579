import { useQuery } from "@apollo/client";
import { AccessTime } from "@styled-icons/material-twotone/AccessTime";
import moment from "moment-timezone";
import React from "react";
import ContentLoader from "react-content-loader";
import { useParams } from "react-router-dom";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import { DEFAULT_TIMEZONE } from "constants/date";
import { VisitDiscountType, VisitMobilityNeeds, VisitSingleResult } from "generated/types";
import CreateVisitForm from "pages/ScheduleVisit/CreateVisitForm";
import { VISIT_QUERY } from "pages/ScheduleVisit/EditVisit/gql";
import { defaultDiscount } from "pages/ScheduleVisit/constants";
import { Values } from "pages/ScheduleVisit/types";
import { nonNull, nonNullWithId } from "utils/nonNull";

const CloneVisit = () => {
  useTopBarOptions({ title: "Clone Visit", icon: AccessTime, breadcrumbs: ["Visits"] });
  const { visitId: visitToCloneId } = useParams<{ visitId?: string }>();

  const { data, loading, error } = useQuery<{ visit?: VisitSingleResult | null }>(VISIT_QUERY, {
    variables: { id: visitToCloneId },
  });

  if (loading) return <SpinningIcon size={28} />;

  const visitToClone = data?.visit?.data ?? {};
  const accountId = visitToClone?.account?.data?.id;

  const visitDiscount = visitToClone?.discount?.data;
  const isFixedDiscount = visitDiscount?.type === VisitDiscountType.FixedAmount;
  const discount = visitDiscount
    ? {
        type: visitDiscount.type ?? defaultDiscount.type,
        valueFixed: isFixedDiscount ? visitDiscount.value ?? defaultDiscount.valueFixed : 0,
        valuePercentage: isFixedDiscount
          ? 0
          : visitDiscount.value ?? defaultDiscount.valuePercentage,
      }
    : defaultDiscount;

  const timezone = visitToClone?.location?.data?.timezone;
  const additionalLocations = nonNull(visitToClone?.additionalLocations?.data).map(
    (additionalLocation) => ({
      id: additionalLocation.id ?? "",
      description: additionalLocation.description ?? "",
      location: additionalLocation,
      stayWithMember: additionalLocation.stayWithMember
        ? "stay"
        : additionalLocation.dropOff
        ? "drop"
        : null,
    })
  );

  const destination = visitToClone?.destination?.data
    ? {
        value: visitToClone.destination.data.id || "",
        label: visitToClone.destination.data.address || "",
        timezone: visitToClone.destination.data.timezone || "",
      }
    : null;

  // remove any impact program related objectives, since they will be
  // automatically assigned on the backend.
  const objectives = nonNullWithId(visitToClone?.objectives?.data)
    .filter((objective) => objective.isImpactProgram === false)
    .map((objective) => ({
      ...objective,
      label: objective.description as string,
      value: objective.id,
    }));

  const initialValues: Values = {
    accountId: accountId || "",
    accountType: visitToClone?.account?.data?.type || "",
    additionalLocations,
    businessId: "",
    businessPolicyEnd: "",
    byDay: visitToClone?.recurrence?.data?.config?.byDay ?? null,
    cardId: visitToClone?.card?.data?.id || "",
    count: visitToClone?.recurrence?.data?.config?.count ?? null,
    delivery: visitToClone?.delivery,
    destination,
    discount,
    endOnDate: !visitToClone?.recurrence?.data?.config?.count,
    estimatedDuration: visitToClone?.estimatedDuration ?? undefined,
    favoritePalsOnly: visitToClone?.favoritePalsOnly ?? false,
    freq: visitToClone?.recurrence?.data?.config?.freq ?? null,
    hasDoctorVisitsTask: false,
    hasGroceryTask: true,
    hasMedicationTask: true,
    hasRelocationTasks: false,
    hasUberTasks: false,
    hasOvernightTransportationTasks: false,
    invitePalIds: nonNullWithId(visitToClone?.invitations?.data).map((p) => p.id),
    isPaymentValid: false,
    isUberEligible: visitToClone?.isUberEligible ?? null,
    acceptsThirdPartyTransportation: null,
    isVirtual: visitToClone?.isVirtual || false,
    location: visitToClone?.location?.data || null,
    memberNote: visitToClone?.memberNote || "",
    mobilityNeeds: VisitMobilityNeeds.NotApplicable,
    note: visitToClone?.note || "",
    objectives: objectives,
    overrideBusinessPolicy: false,
    obpReasonDescription: "",
    palId: null,
    papaId: visitToClone?.papa?.data?.id || "",
    scheduledForDay: moment.tz().toISOString() || "",
    scheduledForTime: visitToClone?.scheduledFor
      ? moment.tz(visitToClone?.scheduledFor, timezone || DEFAULT_TIMEZONE).format("LT")
      : "",
    selectedRecommendedDate: "",
    surveyIds: nonNull(visitToClone?.surveyResponses?.data).map(
      ({ survey }) => survey?.data?.id ?? ""
    ),
    systemNotes: visitToClone?.systemNotes || [],
    taskIds: nonNullWithId(visitToClone?.tasks?.data).map(({ id }) => id),
    validUntil: visitToClone?.recurrence?.data?.validUntil ?? null,
    visibleToPalApp: true,
    overrideVisitLeadTime: false,
    overrideVisitLeadTimeReason: null,
    overrideVisitLeadTimeAdditionalDetails: "",
    validateAcceptsThirdPartyTransportation: false,
  };

  if (!accountId && visitToCloneId && !error) {
    return (
      <ContentLoader
        speed={2}
        width="100%"
        height={1015}
        backgroundColor="#f3f3f3"
        foregroundColor="#c0c0c0"
        uniqueKey="CreateVisitLoader"
      >
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="500" />
        <rect x="0" y="515" rx="3" ry="3" width="100%" height="500" />
      </ContentLoader>
    );
  }
  return (
    <>
      <CreateVisitForm initialValues={initialValues} isCloneMode={true} />
    </>
  );
};

export default CloneVisit;
