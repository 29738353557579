import { useFeatureIsOn } from "@growthbook/growthbook-react";
import includes from "lodash/includes";
import React, { FunctionComponent } from "react";
import { toast } from "react-toastify";

import { confirm } from "components/Confirm";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import Dropdown from "components/Dropdown";
import { ConfirmModalIcon } from "components/Modal/ConfirmModal";
import { FeatureFlags } from "constants/featureFlags";
import { VisitMenuActions } from "constants/visit";
import {
  SupportTicketRequesterType,
  Visit,
  VisitState,
  useResolveVisitFlagMutation,
  useStartAgentVisitMutation,
} from "generated/types";
import { useSubmitSupportTicket } from "hooks/supportTicketing/useSubmitSupportTicket";
import { useCurrentAccount } from "hooks/useCurrentAccount";
import { useCurrentAccountPalId } from "hooks/useCurrentAccountPalId";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useLocalStorage } from "hooks/useLocalStorage";
import { canVisitCanceled } from "utils/canVisitCanceled";
import { filterVisitFlags } from "utils/filterVisitFlags";
import { GET_CURRENT_ACCOUNT } from "utils/gql/current-account";
import { nonNull } from "utils/nonNull";
import * as segment from "utils/segment";

import { DropdownActionMenu } from "../DropdownActionMenu";
import { SubmitFeedbackButton } from "./SubmitFeedbackButton";

interface Props {
  visit: Visit;
  isListMenu?: boolean;
  setAction: (
    action: VisitMenuActions,
    visitId?: string | null,
    palId?: string | null,
    warmTransferData?: any
  ) => void;
  fromPapa?: boolean;
  fromPal?: boolean;
}

const VisitActionsMenu: FunctionComponent<Props> = ({
  visit,
  setAction,
  isListMenu = false,
  fromPal = false,
  fromPapa = false,
}) => {
  const [startAgentVisit, { loading: isStarting }] = useStartAgentVisitMutation();
  const [resolveVisitFlag] = useResolveVisitFlagMutation();
  const currentAccountPalId = useCurrentAccountPalId();
  const currentAccountId = useCurrentAccount()?.id;
  const [visitWatchList] = useLocalStorage<string[]>("visits.watchList", []);
  const { isVisitSuccessAgent, isSupervisor, isBillingSpecialist, isGrievancesAppealsAdmin } =
    useCurrentAccountRole();
  const supportTicketingEnabled = useIsFeatureEnabled(FeatureFlags.SupportTicketing);
  const { loading, submitSupportTicket: handleSubmitSupportTicket } = useSubmitSupportTicket();

  const enableAssignPal = isVisitSuccessAgent || isSupervisor;

  const { manuallyMarkedCriticalFlag, grievancesAppealFlag, needAuditFlag } = filterVisitFlags(
    nonNull(visit.visitFlags?.data)
  );

  const palId = visit?.pal?.data?.id;
  const papaId = visit?.papa?.data?.id;
  const caregiverId = visit?.papa?.data?.caregiver?.data?.id;
  const business = visit?.papa?.data?.business?.data;
  const businessName = business?.name;
  const currentBusinessPolicy = business?.currentBusinessPolicy?.data;
  const warmTransfer = currentBusinessPolicy?.warmTransfer;
  const phoneNumber = currentBusinessPolicy?.warmTransferPhoneNumber;
  const warmTransferData = { businessName: businessName, phoneNumber: phoneNumber } as any;

  const submitPalSupportTicket = () =>
    handleSubmitSupportTicket({
      id: palId,
      type: SupportTicketRequesterType.Pal,
      data: { visitId: visit.id },
    });

  const submitPapaSupportTicket = () => {
    const args = {
      id: papaId,
      type: SupportTicketRequesterType.Papa,
      data: { visitId: visit.id },
    };

    if (warmTransfer) {
      setAction(VisitMenuActions.warmTransfer, visit.id, null, { ...args, ...warmTransferData });
    } else {
      handleSubmitSupportTicket(args);
    }
  };

  const revokePalFromVisit = async (visit: Visit) => {
    if (visit.shouldTerminateOnRevoke) {
      const confirmed = await confirm(
        <>
          <p>
            This pal was assigned to the visit by the member at the time of scheduling and cannot be
            removed. Continuing will cancel the entire visit, and the member will be notified
          </p>
        </>,
        {
          confirmBtnText: "Continue",
          cancelBtnText: "Go back",
          header: "Warning: Removing this Papa Pal will cancel the visit for the member",
          icon: ConfirmModalIcon.none,
        }
      );

      if (confirmed) {
        setAction(VisitMenuActions.RevokePalFromVisit, visit.id);
      }
    } else {
      setAction(VisitMenuActions.RevokePalFromVisit, visit.id);
    }
  };

  const submitCaregiverSupportTicket = () => {
    const args = {
      id: caregiverId,
      type: SupportTicketRequesterType.Caregiver,
      data: { visitId: visit.id },
    };

    if (warmTransfer) {
      setAction(VisitMenuActions.warmTransfer, visit.id, null, { ...args, ...warmTransferData });
    } else {
      handleSubmitSupportTicket(args);
    }
  };

  const linkToViewVisit = fromPal
    ? `/visits/${visit.id}?fromPal=true`
    : fromPapa
    ? `/visits/${visit.id}?fromPapa=true`
    : `/visits/${visit.id}`;

  const handleResolveFlag = async (flagId: string) => {
    await confirm("Are you sure you want to remove this flag?");

    const { data } = await resolveVisitFlag({
      variables: {
        flagId,
        input: {
          description: "Resolved in admin",
        },
      },
    });

    if (data?.resolveVisitFlag?.data?.resolvedAt) {
      toast.success("Successfully removed flag!");
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleStartAgentVisit = async () => {
    try {
      const { data: startAgentVisitData } = await startAgentVisit({
        variables: {
          visitId: visit.id,
          templateCode: "WELLCARE1",
        },
        refetchQueries: [{ query: GET_CURRENT_ACCOUNT }],
      });

      if (startAgentVisitData?.startAgentVisit?.data?.id) {
        toast.success("The virtual started!", { position: "top-center" });
      } else {
        toast.error("It' was not possible to start a virtual call", { position: "top-center" });
      }
    } catch (error) {
      toast.error((error as Error).message, { position: "top-center" });
    }
  };

  const handleMarkVisitAsAudited = async () => {
    if (!(await confirm("Are you sure you want to mark this visit as audited"))) return;

    const markVisitAsAuditedPromise = async () => {
      if (!needAuditFlag?.id || !currentAccountId) return;

      const { data } = await resolveVisitFlag({
        variables: {
          flagId: needAuditFlag.id,
          input: {
            description: "Resolved in admin",
            resolvedById: currentAccountId,
          },
        },
      });

      if (!data?.resolveVisitFlag?.data?.resolvedAt) {
        throw new Error("Failed to resolve need audit flag");
      }
    };

    toast
      .promise(markVisitAsAuditedPromise(), {
        pending: "Marking visit as audited...",
        success: "Visit marked as audited",
        error: {
          render({ data }: { data: Error }) {
            return data.message;
          },
        },
      })
      .catch(() => {});
  };

  const reschedulableStates = [
    VisitState.Pending,
    VisitState.Accepted,
    VisitState.Confirmed,
    VisitState.Enroute,
  ];

  const isCreditsOn = useFeatureIsOn(FeatureFlags.Credits as string);

  const renderDropDownItem = (action: string, onClick: () => void) => {
    return (
      <Dropdown.Item
        actionMenuAnalytics={{
          action: action,
          buttonLocation: "visit action menu",
        }}
        onClick={onClick}
      >
        {action}
      </Dropdown.Item>
    );
  };

  if (loading) return <SpinningIcon size={18} />;

  return (
    <DropdownActionMenu isListMenu={isListMenu}>
      {isListMenu && <Dropdown.Link to={linkToViewVisit}>View visit</Dropdown.Link>}
      {enableAssignPal &&
        visit.state === VisitState.Pending &&
        renderDropDownItem("Assign pal", () =>
          setAction(VisitMenuActions.AssignPalToVisit, visit.id, visit.pal?.data?.id)
        )}
      {!!visit.state && reschedulableStates.includes(visit.state) && (
        <Dropdown.Link to={`/edit-visit/${visit.id}`}>Reschedule visit</Dropdown.Link>
      )}
      {visit.state === VisitState.Started &&
        renderDropDownItem("Edit start time", () =>
          setAction(VisitMenuActions.EditStartTime, visit.id)
        )}
      {renderDropDownItem("Confirm member", () =>
        setAction(VisitMenuActions.ConfirmMember, visit.id)
      )}
      {visit.state === VisitState.Completed || visit.state === VisitState.Reviewed ? (
        renderDropDownItem("Edit visit", () => setAction(VisitMenuActions.EditComplete, visit.id))
      ) : (
        <Dropdown.Link to={`/edit-visit/${visit.id}`}>Edit visit</Dropdown.Link>
      )}
      <Dropdown.Link to={`/clone-visit/${visit.id}`}>Clone visit</Dropdown.Link>
      {canVisitCanceled(visit?.state) &&
        renderDropDownItem("Cancel visit", () => {
          setAction(VisitMenuActions.CancelVisit, visit.id);
        })}
      {visit.state === VisitState.Pending &&
        !manuallyMarkedCriticalFlag &&
        renderDropDownItem("Make critical visit", () =>
          setAction(VisitMenuActions.MakeCritical, visit.id)
        )}
      {!!manuallyMarkedCriticalFlag &&
        renderDropDownItem(
          "Remove critical flag",
          () => manuallyMarkedCriticalFlag.id && handleResolveFlag(manuallyMarkedCriticalFlag.id)
        )}
      {!grievancesAppealFlag &&
        isGrievancesAppealsAdmin &&
        renderDropDownItem("Add G&A Flag", () =>
          setAction(VisitMenuActions.AddGrievancesAppealFlag, visit.id)
        )}
      {!!grievancesAppealFlag &&
        isGrievancesAppealsAdmin &&
        renderDropDownItem(
          "Remove G&A flag",
          () => grievancesAppealFlag.id && handleResolveFlag(grievancesAppealFlag.id)
        )}

      {visit.state === VisitState.Accepted &&
        renderDropDownItem("Confirm", () => setAction(VisitMenuActions.ConfirmVisit, visit.id))}

      {visit.state === VisitState.Confirmed &&
        renderDropDownItem("Revert to accepted", () =>
          setAction(VisitMenuActions.RevertToAccepted, visit.id)
        )}
      {visit.state === VisitState.Confirmed &&
        !visit.isVirtual &&
        renderDropDownItem("Start commute", () =>
          setAction(VisitMenuActions.StartCommute, visit.id)
        )}

      {visit.state === VisitState.Enroute &&
        renderDropDownItem("Revert to confirmed", () =>
          setAction(VisitMenuActions.RevertVisitCommute, visit.id)
        )}
      {(visit.state === VisitState.Enroute ||
        (visit.state === VisitState.Confirmed && visit.isVirtual)) &&
        renderDropDownItem("Start", () => setAction(VisitMenuActions.StartVisit, visit.id))}
      {(visit.state === VisitState.Confirmed ||
        visit.state === VisitState.Pending ||
        visit.state === VisitState.Accepted) &&
        visit.isVirtual &&
        !isStarting &&
        renderDropDownItem("Start agent visit", () => {
          segment.startAgentVisitClicked();
          handleStartAgentVisit();
        })}
      {visit.state === VisitState.Started &&
        renderDropDownItem(visit.isVirtual ? "Revert to confirmed" : "Revert to enroute", () =>
          setAction(VisitMenuActions.RevertVisitStart, visit.id)
        )}
      {visit.state === VisitState.Started &&
        renderDropDownItem("Complete", () => setAction(VisitMenuActions.CompleteVisit, visit.id))}

      {!visit?.recurrence?.data &&
        renderDropDownItem("Make recurring visit", () =>
          setAction(VisitMenuActions.MakeRecurring, visit.id)
        )}

      {visit.state === VisitState.Pending &&
        visit?.tasks?.data?.map((task) => task?.requiresVehicle).includes(true) &&
        renderDropDownItem("Handoff visit", () =>
          setAction(VisitMenuActions.HandoffVisit, visit.id)
        )}

      {visit.state === VisitState.PartnerOperated &&
        renderDropDownItem("Reclaim handoffed visit", () =>
          setAction(VisitMenuActions.ReclaimHandoffedVisit, visit.id)
        )}

      {visit.state === VisitState.PartnerOperated &&
        renderDropDownItem("Complete visit handoff", () =>
          setAction(VisitMenuActions.CompleteVisitHandoff, visit.id)
        )}

      {visit.state &&
        [VisitState.Accepted, VisitState.Confirmed].includes(visit.state) &&
        renderDropDownItem("Remove pal", () => revokePalFromVisit(visit))}
      {visit.isVirtual &&
        visit.state === VisitState.Pending &&
        renderDropDownItem("Accept visit", () => setAction(VisitMenuActions.AcceptVisit, visit.id))}
      {visit.state === VisitState.Reviewed &&
        (isSupervisor || isBillingSpecialist) &&
        renderDropDownItem("Terminate reviewed visit", () =>
          setAction(VisitMenuActions.TerminateReviewedVisit, visit.id)
        )}
      {visit.state === VisitState.Terminated &&
        (isSupervisor || isBillingSpecialist) &&
        renderDropDownItem("Change to reviewed", () =>
          setAction(VisitMenuActions.ReviewTerminatedVisit, visit.id)
        )}

      {visit.pal?.data?.id === currentAccountPalId && (
        <SubmitFeedbackButton visit={visit} setAction={setAction} />
      )}

      {renderDropDownItem(
        includes(visitWatchList, visit.id)
          ? "Remove visit from watch list"
          : "Add visit to watch list",
        () => setAction(VisitMenuActions.VisitWatchList, visit.id)
      )}

      {isBillingSpecialist &&
        !!needAuditFlag &&
        renderDropDownItem("Mark visit as audited", handleMarkVisitAsAudited)}

      {supportTicketingEnabled &&
        palId &&
        renderDropDownItem("Submit Ticket for Pal", submitPalSupportTicket)}

      {supportTicketingEnabled &&
        papaId &&
        renderDropDownItem("Submit Ticket for Papa", submitPapaSupportTicket)}

      {supportTicketingEnabled &&
        caregiverId &&
        renderDropDownItem("Submit ticket for Caregiver", submitCaregiverSupportTicket)}

      {isCreditsOn &&
        renderDropDownItem("Grant credit", () => setAction(VisitMenuActions.GrantCredit, visit.id))}
    </DropdownActionMenu>
  );
};

export default VisitActionsMenu;
