import omit from "lodash/omit";

import { DEFAULT_TIMEZONE } from "constants/date";
import { RECOMMENDED_TIME_SELECTED } from "constants/visit";
import {
  Location,
  VisitDiscountType,
  VisitFlagName,
  VisitRecurrenceFrequency,
} from "generated/types";
import { mergeTimezoneShiftedDateAndTime } from "utils/date";

import { Discount, Values } from "./types";

export enum VisitErrorMessage {
  hourAllotmentReached = "hour_allotment_reached",
  papaCovidVaccination = "papa_covid_vaccination",
  scheduledOutsideBusinessHours = "scheduled_outside_business_hours",
  max_scheduled_visits_per_day_reached = "max_scheduled_visits_per_day_reached",
  cannot_change_transportation_visit_type = "cannot_change_transportation_visit_type",
}

export const maxScheduledVisitsPerDayReachedErrorMessage =
  "The member has reached the maximum number of daily visits permissible by their plan's business policy.";

export const operatingHoursErrorMessage = "Visits must be scheduled to start between 8am and 7pm";

export const cannotChangeTransportationVisitTypeErrorMessage =
  "Visits cannot change between transportation and non-transportation once scheduled.";

export const defaultDiscount = {
  type: VisitDiscountType.FixedAmount,
  valueFixed: 0,
  valuePercentage: 0,
};

export const getTasksWithNoObjectives = (values: Values) => {
  const taskIdsWithNoObjectives: string[] = [];
  const objectiveTaskIds = values.objectives?.map((objective) => objective.task?.data?.id);
  values.taskIds.forEach((taskId) => {
    if (!objectiveTaskIds?.includes(taskId)) {
      taskIdsWithNoObjectives.push(taskId);
    }
  });
  return taskIdsWithNoObjectives;
};

export const mapSubmitFormValues = (values: Values) => {
  const discountType = values.discount.type;
  const { valueFixed, valuePercentage } = values.discount;

  const discount: Discount = {
    type: discountType,
    value: discountType === VisitDiscountType.FixedAmount ? valueFixed : valuePercentage,
  };

  const scheduledFor = mergeTimezoneShiftedDateAndTime({
    day: values.selectedRecommendedDate || values.scheduledForDay,
    time: values.scheduledForTime,
    timezone: values.location?.timezone || DEFAULT_TIMEZONE,
  });

  const additionalLocations = values.additionalLocations
    .filter(
      (additionalLocation) =>
        additionalLocation.location?.address !== "" || additionalLocation.description !== ""
    )
    .map((location) => {
      const cleanLocation = {
        ...omit(location.location, ["__typename", "id"]),
      };
      return {
        ...cleanLocation,
        description: location.description,
        stayWithMember: location.stayWithMember ? location.stayWithMember === "stay" : null,
        dropOff: location.stayWithMember ? location.stayWithMember === "drop" : null,
      };
    });

  const destinationId =
    values?.hasRelocationTasks && values?.destination?.id ? values?.destination?.id : null;

  const delivery = values.hasMedicationTask || values.hasGroceryTask ? values.delivery : null;

  const visitFlags = [
    ...(values.overrideVisitLeadTime
      ? [
          {
            name: VisitFlagName.VisitLeadTimeException,
            reason: values.overrideVisitLeadTimeReason,
            description: values.overrideVisitLeadTimeAdditionalDetails,
          },
        ]
      : []),
  ];

  return {
    ...omitExtraFields(values),
    ...(values.overrideBusinessPolicy
      ? {
          obpReason: {
            code: "OVERRIDE_BUSINESS_POLICY",
            description: values.obpReasonDescription,
          },
        }
      : {}),
    estimatedDuration: values.estimatedDuration || null,
    additionalLocations,
    objectives: values.objectives?.map(({ value }) => ({ id: value })) ?? [],
    discount,
    scheduledFor,
    locationId: values?.location?.id ?? null,
    destinationId,
    delivery,

    nonRecommendedTimeReason:
      values.nonRecommendedTimeReason === RECOMMENDED_TIME_SELECTED
        ? null
        : values.nonRecommendedTimeReason,
    visitFlags,
  };
};

const omitExtraFields = (values: Values) => {
  return omit(values, [
    "accountType",
    "businessId",
    "businessPolicyEnd",
    "byDay",
    "count",
    "destination",
    "endOnDate",
    "firstAvailableDate",
    "freq",
    "hasDoctorVisitsTask",
    "hasGroceryTask",
    "hasMedicationTask",
    "hasRelocationTasks",
    "hasUberTasks",
    "hasOvernightTransportationTasks",
    "isPaymentValid",
    "location",
    "maxMinutesPerVisit",
    "palName",
    "scheduledForDay",
    "scheduledForTime",
    "selectedRecommendedDate",
    "systemNotes",
    "obpReasonDescription",
    "validUntil",
    "nonRecommendedTimeReason",
    "genderMismatch",
    "overrideVisitLeadTimeReason",
    "overrideVisitLeadTimeAdditionalDetails",
    "timeWithinOperatingHours",
    "taskNames",
    "validateAcceptsThirdPartyTransportation",
  ]);
};

export const isByDayRecurrence = (frequency?: VisitRecurrenceFrequency | null) => {
  if (!frequency) return false;

  const states = [VisitRecurrenceFrequency.Fortnightly, VisitRecurrenceFrequency.Weekly];

  return states.some((state) => state === frequency);
};

// For time recommendation feature
const counties = [
  // 9am, 12pm, 3pm
  [
    { state: ["pa", "pennsylvania"], county: ["delaware"] },
    { state: ["il", "illinois"], county: ["lake"] },
    { state: ["mi", "michigan"], county: ["lenawee"] },
    { state: ["ca", "california"], county: ["alameda"] },
  ],

  // 9am, 1pm, 4pm
  [
    { state: ["mo", "missouri"], county: ["clay"] },
    { state: ["nc", "north carolina"], county: ["durham"] },
    { state: ["mn", "minnesota"], county: ["ramsey"] },
    { state: ["nc", "north carolina"], county: ["randolph"] },
  ],
];

export const getTimeOptions = (location?: Location | null): string[] => {
  const { state, county } = location || {};

  if (!state || !county) return [];

  const isInFirstGroup = !!counties[0].some(
    (c) =>
      c.state.includes(state.toLowerCase()) &&
      (c.county.includes(county.toLowerCase()) ||
        c.county.map((c) => `${c} county`).includes(county.toLowerCase()))
  );
  const isInSecondGroup = !!counties[1].some(
    (c) =>
      c.state.includes(state.toLowerCase()) &&
      (c.county.includes(county.toLowerCase()) ||
        c.county.map((c) => `${c} county`).includes(county.toLowerCase()))
  );

  if (isInFirstGroup) return ["9:00 AM", "12:00 PM", "3:00 PM"];
  if (isInSecondGroup) return ["9:00 AM", "1:00 PM", "4:00 PM"];

  return [];
};
