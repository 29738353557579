import React, { useState } from "react";
import { Link } from "react-router-dom";

import { DataTableMenuActions } from "components/DataTableDropdownMenu";
import { Column } from "components/SmartTable/schema";
import { originOptions, priorityOptions } from "constants/service-requests";
import { ServiceRequest, ServiceRequestStatus } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { formatDateTime, mapValueToLabel } from "utils/helpers/formatters";
import { fancyEnum } from "utils/strings/fancyEnum";

type Props = {
  statusType?: string;
};

export const useServicesTableColumns = ({ statusType }: Props) => {
  const { isCareConciergeSpecialist } = useCurrentAccountRole();
  const [action, setAction] = useState(DataTableMenuActions.NoAction);
  const [serviceRequest, setServiceRequest] = useState<ServiceRequest | null>(null);
  let columns: Column<ServiceRequest>[] = [
    {
      header: { title: "Status" },
      dataField: "status",
      formatter({ status, closedReason }) {
        return (
          `${fancyEnum(status!)}` +
          (status === ServiceRequestStatus.Closed && closedReason
            ? ` (${fancyEnum(closedReason)})`
            : "")
        );
      },
    },
    {
      header: { title: "ID" },
      dataField: "id",
      formatter({ papa, id }) {
        return isCareConciergeSpecialist ? (
          <Link to={`/papas/${papa?.data?.id}/care-concierge/${id}/`}>{id}</Link>
        ) : (
          id
        );
      },
    },
    {
      header: { title: "Type" },
      dataField: "type.data.name",
    },
    {
      header: { title: "Need" },
      dataField: "need.data.name",
      formatter({ needText, need }) {
        return need?.data?.name === "Other" ? "Other - " + needText : need?.data?.name;
      },
    },
    {
      header: { title: "Priority" },
      dataField: "priority",
      formatter({ priority }) {
        return mapValueToLabel(priorityOptions, priority);
      },
    },
    {
      header: { title: "Origin" },
      dataField: "origin",
      formatter({ origin }) {
        return mapValueToLabel(originOptions, origin);
      },
    },
    {
      header: { title: "Assignee" },
      dataField: "assignedTo.data.id",
      formatter({ assignedTo }) {
        return assignedTo?.data ? (
          <Link to={`/members/${assignedTo?.data?.id}/`}>{assignedTo?.data?.fullName}</Link>
        ) : (
          "Unassigned"
        );
      },
    },
    {
      header: { title: "Date Opened" },
      dataField: "openedAt",
      formatter: ({ openedAt }) => formatDateTime(openedAt),
    },
  ];
  if (statusType === "open") {
    const checkboxColumn: Column<ServiceRequest> = {
      header: { title: " ", type: "select" },
      cellProps: { width: "54px" },
      dataField: "select",
    };
    columns = [checkboxColumn, ...columns];
  }

  const createServiceRequest = () => {
    setAction(DataTableMenuActions.Create);
    setServiceRequest(null);
  };

  const closeModal = () => {
    setAction(DataTableMenuActions.NoAction);
    setServiceRequest(null);
  };

  return {
    columns,
    action,
    serviceRequest,
    createServiceRequest,
    closeModal,
  };
};
