import React from "react";
import styled from "styled-components";

import Button from "components/Button";
import Heading from "components/Heading";
import Modal from "components/Modal";
import { ModalFooter } from "components/Modal/Modal.styles";
import Text from "components/Text";
import { Values } from "pages/ScheduleVisit/types";

interface Props {
  isOpen: boolean;
  visitFormValues?: Values;
  onSubmit: (values: Values) => Promise<void>;
  toggle: () => void;
}

export const PreferredPalCancelWarningModal: React.FC<Props> = ({
  isOpen,
  visitFormValues,
  onSubmit,
  toggle,
}) => {
  const handleSubmit = async () => {
    if (visitFormValues) {
      await onSubmit(visitFormValues);
      toggle();
    }
  };

  const palNameOrDefault = visitFormValues?.palId
    ? `Pal ${visitFormValues?.palName}`
    : "a Preferred Pal";

  return (
    <Modal isOpen={isOpen}>
      <Modal.Body>
        <Container>
          <TextContainer>
            <Header as="h1">Cancellation Warning </Header>
            <Text bold>
              If {palNameOrDefault} is not available, the Dr. visit will be canceled.
            </Text>
            <Text bold>Are you sure about this Pal setting?</Text>
            <Text>
              This visit might be canceled close to your visit time, which could make it hard for
              you to find an alternative solution.
            </Text>
          </TextContainer>
        </Container>
      </Modal.Body>
      <StyledFooter>
        <Button variant="secondary" onClick={toggle}>
          No, change Pal setting
        </Button>
        <Button type="button" variant="primary" onClick={handleSubmit}>
          Yes, schedule visit
        </Button>
      </StyledFooter>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 32px 0 32px;
`;

const Header = styled(Heading)`
  margin-bottom: 1.5rem;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledFooter = styled(ModalFooter)`
  margin-top: 2rem;
`;
