import { useMutation } from "@apollo/client";
import { InfoCircle } from "@styled-icons/bootstrap/InfoCircle";
import { Formik } from "formik";
import moment, { Moment } from "moment-timezone";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import Button from "components/Button";
import { FormFooter, StyledForm } from "components/EditBio/styles";
import Form from "components/Form";
import InputDateRange from "components/InputDateRange";
import { Loader } from "components/Loader";
import { ModalContainer } from "components/Modal/Modal.styles";
import QueryErrors from "components/QueryErrors";
import Select from "components/Select";
import { DEFAULT_TIMEZONE } from "constants/date";
import { virtualVisitTasks } from "constants/tasks";
import { IntervalUnit, useGetCurrentBusinessPolicyQuery } from "generated/types";
import { useAssessmentOptions } from "hooks/useAssessmentOptions";
import { useCallItClientProgramOrBusiness } from "hooks/useCallItClientProgramOrBusiness";
import { formatDateTime } from "utils/helpers/formatters";

import BusinessPolicySwitches from "./BusinessPolicySwitches";
import HourAllotment from "./HourAllotment";
import TaskSelect from "./TaskSelect";
import { CREATE_BUSINESS_POLICY, CREATE_MAX_TIME_RULE } from "./gql";
import { Schema, schema } from "./schema";
import { useBillingFormulaOptions } from "./useBillingFormulaOptions";
import { useTaskSelectOptions } from "./useTaskSelectOptions";

interface Props {
  accountId: string;
  onClose: () => void;
  onCreateFinish: () => void;
}

type BusinessPolicyParams = { businessId: string };

const BusinessPolicyForm: FunctionComponent<Props> = ({ accountId, onClose, onCreateFinish }) => {
  const { capitalized, singularEntityName } = useCallItClientProgramOrBusiness();

  const { businessId } = useParams<BusinessPolicyParams>();
  const { assessmentOptions } = useAssessmentOptions();

  const { options, loading, error: tasksError } = useTaskSelectOptions();
  const { formulaOptions } = useBillingFormulaOptions();
  const {
    data: currentBusinessPolicyData,
    loading: businessPoliciesLoading,
    error: businessPoliciesError,
  } = useGetCurrentBusinessPolicyQuery({
    variables: {
      accountId,
    },
  });
  const error = tasksError || businessPoliciesError;
  const businessPolicies = useMemo(
    () => currentBusinessPolicyData?.account?.data?.businessPolicies?.data || [],
    [currentBusinessPolicyData]
  );
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, "months"));
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [createBusinessPolicy] = useMutation(CREATE_BUSINESS_POLICY);
  const [createMaxTimeRule] = useMutation(CREATE_MAX_TIME_RULE);

  const currentBusinessPolicy =
    currentBusinessPolicyData?.account?.data?.currentBusinessPolicy?.data;
  const maxTimeRule = currentBusinessPolicy?.maxTimeRules?.data?.[0];
  const numberOfHours = maxTimeRule?.numberOfMinutes
    ? Math.round(maxTimeRule.numberOfMinutes / 60)
    : 10;

  const initialValues = {
    dateRange: {
      startDate: currentBusinessPolicy?.startsAt
        ? moment(currentBusinessPolicy.startsAt)
        : startDate,
      endDate: currentBusinessPolicy?.endsAt ? moment(currentBusinessPolicy.endsAt) : endDate,
    },
    numberOfHours: numberOfHours,
    intervalUnit: IntervalUnit.Months,
    billingFormulaId:
      (formulaOptions?.find((formula) => formula.isDefault === true)?.value as string) ?? "",
    inPersonDisallowedTaskIds: currentBusinessPolicy?.inPersonDisallowedTasks?.data?.length
      ? currentBusinessPolicy.inPersonDisallowedTasks.data.map((task) => task?.id as string)
      : [],
    virtualDisallowedTaskIds: currentBusinessPolicy?.virtualDisallowedTasks?.data?.length
      ? currentBusinessPolicy.virtualDisallowedTasks.data.map((task) => task?.id as string)
      : [],
    allowInPersonVisits: currentBusinessPolicy?.allowInPersonVisits ?? true,
    allowVirtualVisits: currentBusinessPolicy?.allowVirtualVisits ?? true,
    lonelinessCompliant: currentBusinessPolicy?.lonelinessCompliant ?? false,
    palCovidVaccination: currentBusinessPolicy?.palCovidVaccination ?? false,
    papaCovidVaccination: currentBusinessPolicy?.papaCovidVaccination ?? false,
    allowUberForTransportation: currentBusinessPolicy?.allowUberForTransportation ?? false,
    careConciergeEnabled: currentBusinessPolicy?.careConciergeEnabled ?? true,
    paysForCareConcierge: currentBusinessPolicy?.paysForCareConcierge,
    maxMinutesPerVisit: currentBusinessPolicy?.maxMinutesPerVisit?.toString() ?? "",
    hiddenAssessments: [],
    warmTransfer: currentBusinessPolicy?.warmTransfer ?? false,
    warmTransferPhoneNumber: currentBusinessPolicy?.warmTransferPhoneNumber ?? "",
    maxVisitDistanceMi: currentBusinessPolicy?.maxVisitDistanceMi ?? null,
    maxScheduledVisitsPerDay: currentBusinessPolicy?.maxScheduledVisitsPerDay ?? null,
  };

  useEffect(() => {
    if (businessPolicies.length > 0) {
      const maxEnd = businessPolicies
        .map((businessPolicy) => businessPolicy?.endsAt)
        .sort()
        .reverse()[0];
      setStartDate(moment(maxEnd).add(1, "day"));
      setEndDate(moment(maxEnd).add(1, "day").add(1, "months"));
    }
  }, [businessPolicies]);

  const getVirtualOptions = () =>
    options?.filter(({ label }) => virtualVisitTasks.includes(label)) || [];

  const isOutsideRange = (day: Moment): boolean => {
    let result = day.isSameOrBefore(moment());
    businessPolicies.forEach((businessPolicy) => {
      result =
        result || day.isBetween(businessPolicy?.startsAt, businessPolicy?.endsAt, undefined, "[]");
    });

    return result;
  };

  const handleSubmit = async (formData: Schema) => {
    const {
      numberOfHours,
      intervalUnit,
      dateRange: { endDate, startDate },
      inPersonDisallowedTaskIds,
      virtualDisallowedTaskIds,
      allowInPersonVisits,
      maxMinutesPerVisit,
      hiddenAssessments,
      maxVisitDistanceMi,
      maxScheduledVisitsPerDay,
      ...businessPolicyInput
    } = formData;

    const startsAt = startDate
      ?.tz(DEFAULT_TIMEZONE)
      .set({ hour: 0, minute: 0, second: 0 })
      .format();
    const endsAt = endDate?.tz(DEFAULT_TIMEZONE).set({ hour: 23, minute: 59, second: 0 }).format();
    const allTaskIds = options?.map(({ value }) => value) || [];
    const virtualTaskIds = getVirtualOptions().map(({ value }) => value);

    const maxTimeRuleInput = {
      numberOfMinutes: numberOfHours * 60,
      intervalUnit: intervalUnit,
    };

    try {
      const { data } = await createBusinessPolicy({
        variables: {
          input: {
            accountId,
            businessId,
            allowInPersonVisits,
            ...businessPolicyInput,
            maxMinutesPerVisit: maxMinutesPerVisit ? +maxMinutesPerVisit : null,
            startsAt,
            endsAt,
            inPersonAllowedTaskIds: inPersonDisallowedTaskIds?.length
              ? allTaskIds.filter((id) => !inPersonDisallowedTaskIds.includes(id))
              : allTaskIds,
            inPersonDisallowedTaskIds,
            virtualAllowedTaskIds: virtualDisallowedTaskIds?.length
              ? virtualTaskIds.filter((id) => !virtualDisallowedTaskIds.includes(id))
              : virtualTaskIds,
            virtualDisallowedTaskIds,
            hiddenSurveyIds: hiddenAssessments,
            maxVisitDistanceMi: maxVisitDistanceMi ? +maxVisitDistanceMi : null,
            maxScheduledVisitsPerDay: maxScheduledVisitsPerDay ? +maxScheduledVisitsPerDay : null,
            ...maxTimeRuleInput,
          },
        },
      });

      const businessPolicyId = data?.createBusinessPolicy?.data?.id;

      if (!businessPolicyId) {
        throw new Error("Something is wrong");
      }

      const { data: maxTimeRuleData } = await createMaxTimeRule({
        variables: {
          input: {
            businessPolicyId,
            ...maxTimeRuleInput,
          },
        },
      });

      if (maxTimeRuleData?.createMaxTimeRule?.data) {
        toast.success(`${singularEntityName} Policy was created with success!`);
        onCreateFinish();
        onClose();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  if (error)
    return (
      <ErrorContainer>
        <ModalContainer>
          <QueryErrors error={error} />
        </ModalContainer>
        <FormFooter>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </FormFooter>
      </ErrorContainer>
    );

  if (loading || businessPoliciesLoading) return <Loader />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      <StyledForm role="form">
        <ModalContainer>
          <Form.Group>
            <Form.Label>
              {capitalized.singularEntityName} rules validity period
              {businessPolicies.length > 0 && (
                <StyledInfoCircle size={16} onClick={() => setIsInfoVisible(!isInfoVisible)} />
              )}
            </Form.Label>
            {isInfoVisible && (
              <BusinessPoliciesInfo>
                <small>
                  {capitalized.singularEntityName} already has {singularEntityName} policies:
                </small>
                <ul>
                  {businessPolicies.map((businessPolicy) => (
                    <li key={businessPolicy?.startsAt}>
                      <small>
                        From {formatDateTime(businessPolicy?.startsAt, { format: "L" })} to{" "}
                        {formatDateTime(businessPolicy?.endsAt, { format: "L" })}
                      </small>
                    </li>
                  ))}
                </ul>
              </BusinessPoliciesInfo>
            )}
            <InputDateRange
              id="dateRange"
              name="dateRange"
              isOutsideRange={isOutsideRange}
              minimumNights={0}
            />
          </Form.Group>

          <HourAllotment />

          <Form.Group>
            <Form.Label>Billing Formula</Form.Label>
            <Select name="billingFormulaId" options={formulaOptions} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Tasks not permitted - in person</Form.Label>
            <TaskSelect name="inPersonDisallowedTaskIds" options={options} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Tasks not permitted - virtual</Form.Label>
            <TaskSelect
              name="virtualDisallowedTaskIds"
              options={getVirtualOptions()}
              menuPlacement="top"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Hidden assessments (invisible to agents)</Form.Label>
            <Select name="hiddenAssessments" options={assessmentOptions} isMulti />
          </Form.Group>

          <Form.Group>
            <Form.Label>Max minutes per visit</Form.Label>
            <Form.Input type="number" name="maxMinutesPerVisit" aria-label="maxMinutesPerVisit" />
          </Form.Group>

          <Form.Group>
            <Form.Label>Max visit distance (miles; optional)</Form.Label>
            <Form.Input
              type="number"
              min="0"
              step="1"
              name="maxVisitDistanceMi"
              aria-label="maxVisitDistanceMi"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Max scheduled visits per day (optional)</Form.Label>
            <Form.Input
              type="number"
              min="1"
              step="1"
              name="maxScheduledVisitsPerDay"
              aria-label="maxScheduledVisitsPerDay"
            />
          </Form.Group>

          <BusinessPolicySwitches />
        </ModalContainer>

        <FormFooter>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </FormFooter>
      </StyledForm>
    </Formik>
  );
};

const ErrorContainer = styled.div`
  width: 100%;
`;

const StyledInfoCircle = styled(InfoCircle)`
  color: #0d2973;
  margin-left: 0.5rem;
  cursor: pointer;
`;

const BusinessPoliciesInfo = styled.div`
  background: #0d2973;
  color: white;
  padding: 0 0.5rem;
  border-radius: 4px;
  width: 16rem;
  margin-bottom: 0.5rem;
  ul {
    margin: 0;
    padding: 0 0 0 1rem;
  }
`;

export default BusinessPolicyForm;
