import { useMutation } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import React, { ReactElement, useRef } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import { SpinningIcon } from "components/CustomIcon/Spinning";
import { GET_VISITS_LIST } from "components/DataTables/VisitsTable/gql";
import Form from "components/Form";
import { ModalContainer, ModalFooter, ModalStyledForm } from "components/Modal/Modal.styles";
import { ModalLayout } from "components/Modal/ModalLayout";
import Select from "components/Select";

import { reasonOptions } from "../CancelVisit/models";
import { REVOKE_PAL_FROM_VISIT } from "./gql";
import { Schema, schema } from "./schema";

interface Props {
  id: string;
  isOpen: boolean;
  toggle: () => void;
}

const RevokePalFromVisitModal = ({ id, isOpen, toggle }: Props): ReactElement => {
  const formikRef = useRef<FormikProps<Schema>>(null);
  const [revokePalFromVisit, { loading }] = useMutation(REVOKE_PAL_FROM_VISIT, {
    refetchQueries: [{ query: GET_VISITS_LIST }],
  });

  const initialValues: Schema = {
    reason: null,
    description: "",
  };

  const handleSubmit = async ({ reason: code, description }: Schema) => {
    const input = {
      reason: {
        code,
        description: description || "Removed pal by Admin",
      },
    };
    try {
      const { data } = await revokePalFromVisit({ variables: { id: id, input } });

      if (data?.revokePalFromVisit?.data?.id) {
        toast.success("Remove pal successful!");
        toggle();
      } else {
        throw new Error("Something is wrong");
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ModalLayout toggle={toggle} title="Do you want to remove current pal?" isOpen={isOpen}>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <ModalStyledForm>
          <ModalContainer>
            <Form.Group>
              <Form.Label required>Reason</Form.Label>
              <Select
                name="reason"
                aria-label="reason"
                options={reasonOptions}
                placeholder="Select reason"
                isSearchable={false}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.TextArea aria-label="description" name="description" rows={6} />
            </Form.Group>
          </ModalContainer>
          <ModalFooter>
            <Button type="button" variant="secondary" onClick={toggle}>
              No
            </Button>
            <Button type="submit" disabled={loading} variant="primary">
              {loading && <SpinningIcon size={18} />}
              Yes
            </Button>
          </ModalFooter>
        </ModalStyledForm>
      </Formik>
    </ModalLayout>
  );
};

export default RevokePalFromVisitModal;
