import React from "react";

import Conditional from "components/Conditional";
import Feedback from "components/Form/FormFeedback";
import { ScheduleVisitLabel } from "components/Form/ScheduleVisitLabel";
import YesNoRadioGroup from "components/YesNoRadioGroup";

interface Props {
  acceptsThirdPartyTransportation: boolean | null;
  onChange: (value: boolean) => void;
  getShouldAskQuestion: () => boolean;
  hasError: boolean;
}

/*
Here we determing if a member is eligible for third party transportation. 
Most of third party transportation eligibility is determined
elsewhere. We only render this component if all other eligibility criteria have been met.
Here we just ask if a member is willing to use third party transportation. 
If they are, we consider them eligible. 

This component has intentionally been decoupled from Formik.
*/
const ThirdPartyTransportation = ({
  acceptsThirdPartyTransportation,
  onChange,
  getShouldAskQuestion,
  hasError,
}: Props) => {
  return (
    <Conditional show={getShouldAskQuestion()}>
      <ScheduleVisitLabel>Third party transportation</ScheduleVisitLabel>
      <Conditional show={hasError}>
        <Feedback isInvalid>Required</Feedback>
      </Conditional>
      <YesNoRadioGroup
        name="acceptsThirdPartyTransportation"
        label="In an event that no pals are available for this visit, would you be okay with using a third-party transportation service (e.g Uber) for pickup and dropoff?"
        value={acceptsThirdPartyTransportation}
        onChange={onChange}
      />
    </Conditional>
  );
};

export default ThirdPartyTransportation;
