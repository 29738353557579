import { useQuery } from "@apollo/client";
import { TrashFill } from "@styled-icons/bootstrap/TrashFill";
import { PlusCircle } from "@styled-icons/boxicons-solid/PlusCircle";
import { FieldArray, FieldArrayRenderProps, useField, useFormikContext } from "formik";
import intersection from "lodash/intersection";
import uniqueId from "lodash/uniqueId";
import React, { useState } from "react";
import styled, { css } from "styled-components";

import Button, { ButtonLink } from "components/Button";
import Conditional from "components/Conditional";
import Form from "components/Form";
import { InputContainer } from "components/Form/FormInput";
import { ScheduleVisitLabel } from "components/Form/ScheduleVisitLabel";
import GoogleLocationSearch from "components/GoogleLocationSearch";
import Grid from "components/Grid";
import { LocationFormModal } from "components/LocationForm/LocationFormModal";
import LocationSearch from "components/LocationSearch";
import Select from "components/Select";
import SurveysSelect from "components/SurveysSelect";
import TaskButtons from "components/TaskButtons";
import { TaskObjectives } from "components/TaskObjectives/TaskObjectives";
import Text from "components/Text";
import { FeatureFlags } from "constants/featureFlags";
import { VisitMobilityNeeds } from "generated/types";
import { useCurrentAccountRole } from "hooks/useCurrentAccountRole";
import { useModalToggle } from "hooks/useModalToggle";
import { AdditionalLocationScheduleInput, Values } from "pages/ScheduleVisit/types";
import {
  EnabledFeaturesForCurrentAccountData,
  GET_ENABLED_FEATURES_FOR_CURRENT_ACCOUNT,
} from "utils/gql/current-account";

import DeliveryOptions from "./DeliveryOptions";
import MobilitySupport from "./MobilitySupport";
import PalVisibilityOptions from "./PalVisibilityOptions";
import { DOCTOR, ERRANDS, GROCERY, MEDICATION, TRANSPORTATION } from "./constants";

interface Props {
  disableVisibility?: boolean;
  isEditMode?: boolean;
  showNewPalSettings?: boolean;
  sectionNumber?: number;
  isOptedIntoSMS: boolean;
}

const TaskDetails = ({
  showNewPalSettings = false,
  disableVisibility = false,
  isEditMode = false,
  sectionNumber,
  isOptedIntoSMS,
}: Props) => {
  const { errors, values, isSubmitting } = useFormikContext<Values>();
  const [isMaxTimeReached, setIsMaxTimeReached] = useState(false);
  const { isOpen: addDestinationModalOpen, toggle: toggleAddDestinationModal } = useModalToggle();
  const [{ value: mobilityNeeds }, , mobilityNeedsHelpers] = useField("mobilityNeeds");
  const [{ value: taskNames }, ,] = useField("taskNames");
  const [{ value: isDelivery }, , { setValue: setIsDelivery }] = useField("delivery");
  const { isCareConciergeSpecialist, isEngineeringAdmin, isSuperAdmin, isVisitSuccessAgent } =
    useCurrentAccountRole();
  const { data: enabledFeaturesData } = useQuery<EnabledFeaturesForCurrentAccountData>(
    GET_ENABLED_FEATURES_FOR_CURRENT_ACCOUNT
  );

  const handleDeleteLocation =
    (additionalLocation: AdditionalLocationScheduleInput, arrayHelpers: FieldArrayRenderProps) =>
    () => {
      const currentLocation = additionalLocation.location.address || "this location";

      if (window.confirm(`Are you sure that you want delete ${currentLocation}?`)) {
        const indexDelete = values.additionalLocations.findIndex(
          (item) => item.location === additionalLocation.location
        );
        arrayHelpers.remove(indexDelete);
      }
    };

  const handleAddLocation = (arrayHelpers: FieldArrayRenderProps) => () => {
    arrayHelpers.push({
      id: Math.random(),
      location: {
        address: "",
        description: "",
      },
      stayWithMember: "",
    });
  };

  const handleMobilityNeedChange = (need: VisitMobilityNeeds) => {
    /* Because we're doing a bit of a hacky thing in MobilitySupport by using a useEffect
     we need to make sure the value has actually changed so that we don't create 
     an infinite render loop */
    if (need !== mobilityNeeds) {
      mobilityNeedsHelpers.setValue(need, false);
    }
  };

  const showAdditionalLocation = !values.isVirtual;
  const showSurvey =
    enabledFeaturesData?.currentAccount?.data?.enabledFeatures.includes(
      FeatureFlags.ShowSurveysForPalToConduct
    ) &&
    (isCareConciergeSpecialist || isEngineeringAdmin || isSuperAdmin || isVisitSuccessAgent);

  const showVisibleToPalApp = isEngineeringAdmin || isSuperAdmin || isVisitSuccessAgent;

  // Transportation Tasks
  const mobilityTasks = [DOCTOR, GROCERY, TRANSPORTATION, ERRANDS, MEDICATION];
  const hasMobilityTask = !isDelivery && hasCommonElements(taskNames, mobilityTasks);

  return (
    <Form.Panel number={sectionNumber} headerText="Task details">
      <ScheduleVisitLabel>
        Tasks
        {isMaxTimeReached && " (Out of assets, certain tasks are not available)"}
      </ScheduleVisitLabel>

      <Form.Group>
        <TaskButtons name="taskIds" setIsMaxTimeReached={setIsMaxTimeReached} />
      </Form.Group>

      <DeliveryOptions
        hasMedicationTask={values.hasMedicationTask}
        hasGroceryTask={values.hasGroceryTask}
        delivery={values.delivery}
        onDeliveryChange={setIsDelivery}
      />

      <Conditional show={!isEditMode}>
        <MobilitySupport
          tasks={taskNames}
          hasMobilityTask={hasMobilityTask}
          papaId={values.papaId}
          onChange={handleMobilityNeedChange}
          hasError={!!errors.mobilityNeeds}
        />
      </Conditional>

      <ScheduleVisitLabel>Objectives</ScheduleVisitLabel>
      <>
        {values.hasRelocationTasks && (
          <>
            <Form.Group controlId="destination">
              <Form.Label>Destination</Form.Label>
              <DestinationContainer data-testid="destination">
                <LocationSearch name="destination" papaId={values.papaId} />
                <AddDestinationButton
                  type="button"
                  disabled={!values.papaId}
                  onClick={toggleAddDestinationModal}
                >
                  Add Destination
                </AddDestinationButton>
              </DestinationContainer>
            </Form.Group>

            <LocationFormModal
              isOpen={addDestinationModalOpen}
              toggle={toggleAddDestinationModal}
              papaId={values?.papaId}
            />
          </>
        )}
      </>

      <Grid>
        <Grid.Column size={2}>
          <Form.Group>
            <Form.Label>Objectives</Form.Label>

            <TaskObjectives
              taskIds={values.taskIds}
              objectives={values.objectives}
              papaId={values.papaId}
            />
          </Form.Group>

          {showSurvey && (
            <Form.Group controlId="surveyIds">
              <Form.Label>Surveys for Pal to conduct</Form.Label>
              <SurveysSelect name="surveyIds" />
            </Form.Group>
          )}
        </Grid.Column>
        <Grid.Column size={4}>
          <Form.Group controlId="memberNote">
            <Form.Label>Note from the member</Form.Label>
            <Form.TextArea rows={6} name="memberNote" />
          </Form.Group>
          <Form.Group controlId="systemNotes">
            <Text color="primary">System notes</Text>
            <ul>
              {values.systemNotes &&
                values.systemNotes.map((note) => (
                  <li color="primary" key={uniqueId()} dangerouslySetInnerHTML={{ __html: note }} />
                ))}
            </ul>
          </Form.Group>
        </Grid.Column>
      </Grid>

      <>
        {showVisibleToPalApp && (
          <Form.Group controlId="visibleToPalApp">
            <Form.Check
              controlId="visibleToPalApp"
              name="visibleToPalApp"
              label="Is this visible to pal app?"
            />
          </Form.Group>
        )}
      </>
      <>
        {!showNewPalSettings && (
          <>
            <PalVisibilityOptions disabled={disableVisibility} isEditMode={isEditMode} />
          </>
        )}
      </>

      <>
        {showAdditionalLocation && (
          <>
            <ScheduleVisitLabel>Additional destinations</ScheduleVisitLabel>
            <FieldArray
              name="additionalLocations"
              render={(arrayHelpers) => (
                <>
                  {values.additionalLocations &&
                    !!values.additionalLocations.length &&
                    values.additionalLocations.map((additionalLocation, index) => (
                      // We are using index as our key which is safe as long as we do not
                      // reorder the list
                      <Form.Group key={additionalLocation.id} controlId="location">
                        <Form.Label>{additionalLocation.location?.address}</Form.Label>
                        <LocationRow>
                          <LocationCol flexGrow>
                            <GoogleLocationSearch name={`additionalLocations.${index}.location`} />
                          </LocationCol>
                          <LocationCol isOneQuarter>
                            <Form.Input
                              name={`additionalLocations.${index}.description`}
                              placeholder="Location description"
                              aria-label="location description"
                            />
                          </LocationCol>
                          <LocationCol isOneQuarter>
                            <Select
                              name={`additionalLocations.${index}.stayWithMember`}
                              options={[
                                { value: "stay", label: "Yes" },
                                {
                                  value: "drop",
                                  label: "No, I’m dropping off",
                                },
                              ]}
                              placeholder="Stay with member"
                              isSearchable={false}
                              aria-label="stay with member"
                            />
                          </LocationCol>
                          <LocationCol>
                            <Button
                              type="button"
                              variant="danger"
                              disabled={isSubmitting}
                              onClick={handleDeleteLocation(additionalLocation, arrayHelpers)}
                            >
                              <TrashFill size={16} />
                            </Button>
                          </LocationCol>
                        </LocationRow>
                      </Form.Group>
                    ))}
                  <ButtonLink
                    type="button"
                    variant="text"
                    disabled={isSubmitting}
                    onClick={handleAddLocation(arrayHelpers)}
                  >
                    <PlusCircle size={13} /> Add additional destination
                  </ButtonLink>
                </>
              )}
            />
          </>
        )}
      </>
    </Form.Panel>
  );
};

const LocationRow = styled.div`
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
  flex-direction: column;
  background-color: #f7f9ff;
  padding: 0.5rem;

  & + & {
    margin-top: 1rem;
  }

  @media (min-width: 75rem) {
    flex-direction: row;
    background-color: transparent;
    padding: 0;

    & + & {
      margin-top: 0;
    }
  }
`;

type LocationColProps = {
  isOneQuarter?: boolean;
  flexGrow?: boolean;
};

const LocationCol = styled.div<LocationColProps>`
  padding-left: 1rem;
  padding-right: 1rem;
  flex-basis: 0;

  & + & {
    margin-top: 1rem;
  }

  ${InputContainer} {
    max-width: 18.75rem;
  }

  @media (min-width: 75rem) {
    & + & {
      margin-top: 0;
    }

    ${({ flexGrow }) =>
      flexGrow &&
      css`
        flex-grow: 1;
        flex-shrink: 1;
      `}
    ${({ isOneQuarter }) =>
      isOneQuarter &&
      css`
        flex: none;
        width: 25%;
      `}

    ${InputContainer} {
      width: 100%;
      max-width: 100%;
    }
  }
`;

const DestinationContainer = styled("div")`
  max-width: 485px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const AddDestinationButton = styled(Button)`
  height: 38px;
  display: flex;
  align-items: center;
`;

const hasCommonElements = (list1: string[], list2: string[]): boolean => {
  const commonElements = intersection(list1, list2);
  return commonElements.length > 0;
};

export default TaskDetails;
