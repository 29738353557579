import { IfFeatureEnabled } from "@growthbook/growthbook-react";
import React from "react";

import { Drawer } from "components/Drawer";
import Feedback from "components/Form/FormFeedback";
import { Loader } from "components/Loader";
import { ModalLayout } from "components/Modal/ModalLayout";
import { FeedbackModalsContainer } from "components/VirtualCall/VisitFeedback/FeedbackModalsContainer";
import { FeatureFlags } from "constants/featureFlags";
import { VisitMenuActions } from "constants/visit";
import { useVisitPapaAllotmentPeriodsQuery } from "generated/types";
import { AcceptVisitModal } from "modals/VisitActionModals/AcceptVisit";
import AddGrievancesAppealFlagModal from "modals/VisitActionModals/AddGrievancesAppealFlagModal";
import AssignPalToVisitForm from "modals/VisitActionModals/AssignPalToVisit/AssignPalToVisitForm";
import CancelVisitForm from "modals/VisitActionModals/CancelVisit/CancelVisitForm";
import { CompleteVisitHandoff } from "modals/VisitActionModals/CompleteVisitHandoff";
import { CompleteVisitModal } from "modals/VisitActionModals/CompleteVisitModal";
import { ConfirmMemberModal } from "modals/VisitActionModals/ConfirmMember/ConfirmMember";
import { ConfirmVisitModal } from "modals/VisitActionModals/ConfirmVisitModal";
import { EditStartTime } from "modals/VisitActionModals/EditStartTime";
import { HandoffVisitModal } from "modals/VisitActionModals/HandoffVisitModal";
import MakeCriticalForm from "modals/VisitActionModals/MakeCritical/MakeCriticalForm";
import MakeRecurringVisitModal from "modals/VisitActionModals/MakeRecurringVisitModal";
import { ReclaimHandoffedVisitModal } from "modals/VisitActionModals/ReclaimHandoffedVisitModal";
import { RevertToAcceptedModal } from "modals/VisitActionModals/RevertToAcceptedModal";
import { RevertVisitCommuteModal } from "modals/VisitActionModals/RevertVisitCommuteModal";
import { RevertVisitStartModal } from "modals/VisitActionModals/RevertVisitStartModal";
import ReviewTerminatedVisitModal from "modals/VisitActionModals/ReviewTerminatedVisitModal";
import RevokePalFromVisitModal from "modals/VisitActionModals/RevokePalFromVisitModal";
import { StartCommuteModal } from "modals/VisitActionModals/StartCommuteModal";
import { StartVisitModal } from "modals/VisitActionModals/StartVisitModal";
import TerminateReviewedVisitModal from "modals/VisitActionModals/TerminateReviewedVisitModal";
import { VisitWatchListModal } from "modals/VisitActionModals/VisitWatchList";
import WarmTransferModal from "modals/WarmTransferModal";
import AddCredit from "pages/Papas/Details/Hours/AddCredit";
import EditCompletedVisit from "pages/ScheduleVisit/EditVisit/EditCompletedVisit";

interface Props {
  action: VisitMenuActions;
  visitId: string | null;
  warmTransferData: any;
  onClose: () => void;
}

const Actions = ({ action, visitId, warmTransferData, onClose }: Props) => {
  const onCloseCancelModal = (id?: string) => {
    onClose();
  };

  if (!visitId) return null;

  return (
    <>
      <ModalLayout
        toggle={onClose}
        title="Assign Pal to visit"
        isOpen={action === VisitMenuActions.AssignPalToVisit}
      >
        <AssignPalToVisitForm visitId={visitId} closeModal={onClose} />
      </ModalLayout>

      <ModalLayout
        toggle={onClose}
        title="Edit start time"
        isOpen={action === VisitMenuActions.EditStartTime}
      >
        <EditStartTime onEditFinish={onClose} visitId={visitId} />
      </ModalLayout>

      <ConfirmMemberModal
        id={visitId}
        isOpen={action === VisitMenuActions.ConfirmMember}
        toggle={onClose}
      />

      <ConfirmVisitModal
        id={visitId}
        isOpen={action === VisitMenuActions.ConfirmVisit}
        toggle={onClose}
      />

      <RevertToAcceptedModal
        id={visitId}
        isOpen={action === VisitMenuActions.RevertToAccepted}
        toggle={onClose}
      />

      <StartCommuteModal
        id={visitId}
        isOpen={action === VisitMenuActions.StartCommute}
        toggle={onClose}
      />

      <RevertVisitCommuteModal
        id={visitId}
        isOpen={action === VisitMenuActions.RevertVisitCommute}
        toggle={onClose}
      />

      <StartVisitModal
        id={visitId}
        isOpen={action === VisitMenuActions.StartVisit}
        toggle={onClose}
      />

      <RevertVisitStartModal
        id={visitId}
        isOpen={action === VisitMenuActions.RevertVisitStart}
        toggle={onClose}
      />

      <CompleteVisitModal
        visitId={visitId}
        isOpen={action === VisitMenuActions.CompleteVisit}
        toggle={onClose}
      />

      <ModalLayout
        toggle={onClose}
        title="Make this visit critical?"
        isOpen={action === VisitMenuActions.MakeCritical}
      >
        <MakeCriticalForm visitId={visitId} closeModal={onClose} />
      </ModalLayout>

      <AddGrievancesAppealFlagModal
        visitId={visitId}
        isOpen={action === VisitMenuActions.AddGrievancesAppealFlag}
        toggle={onClose}
      />

      <ModalLayout
        toggle={onClose}
        title="Do you want to cancel the visit?"
        isOpen={action === VisitMenuActions.CancelVisit}
      >
        <CancelVisitForm visitId={visitId} closeModal={onCloseCancelModal} />
      </ModalLayout>

      <MakeRecurringVisitModal
        id={visitId}
        isOpen={action === VisitMenuActions.MakeRecurring}
        toggle={onClose}
      />

      <RevokePalFromVisitModal
        id={visitId}
        isOpen={action === VisitMenuActions.RevokePalFromVisit}
        toggle={onClose}
      />

      <EditCompletedVisit
        visitId={visitId}
        isOpen={action === VisitMenuActions.EditComplete}
        toggle={onClose}
      />

      <ReclaimHandoffedVisitModal
        id={visitId}
        isOpen={action === VisitMenuActions.ReclaimHandoffedVisit}
        toggle={onClose}
      />

      <HandoffVisitModal
        id={visitId}
        isOpen={action === VisitMenuActions.HandoffVisit}
        toggle={onClose}
      />

      <CompleteVisitHandoff
        visitId={visitId}
        isOpen={action === VisitMenuActions.CompleteVisitHandoff}
        toggle={onClose}
      />

      <FeedbackModalsContainer
        visitId={visitId}
        toggle={onClose}
        isOpen={action === VisitMenuActions.Feedback}
      />

      <AcceptVisitModal
        visitId={visitId}
        toggle={onClose}
        isOpen={action === VisitMenuActions.AcceptVisit}
      />

      <VisitWatchListModal
        visitId={visitId}
        toggle={onClose}
        isOpen={action === VisitMenuActions.VisitWatchList}
      />

      <TerminateReviewedVisitModal
        visitId={visitId}
        toggle={onClose}
        isOpen={action === VisitMenuActions.TerminateReviewedVisit}
      />

      <ReviewTerminatedVisitModal
        visitId={visitId}
        toggle={onClose}
        isOpen={action === VisitMenuActions.ReviewTerminatedVisit}
      />

      <WarmTransferModal
        id={warmTransferData.id}
        type={warmTransferData.type}
        data={warmTransferData.data}
        businessName={warmTransferData.businessName}
        phoneNumber={warmTransferData.phoneNumber}
        isOpen={action === VisitMenuActions.warmTransfer}
        closeModal={onClose}
      />

      <IfFeatureEnabled feature={FeatureFlags.Credits}>
        <Drawer
          onClose={onClose}
          title="Credit"
          open={action === VisitMenuActions.GrantCredit}
          size="sm"
        >
          <AddCreditWithVisit visitId={visitId} onClose={onClose} />
        </Drawer>
      </IfFeatureEnabled>
    </>
  );
};

const AddCreditWithVisit = ({ visitId, onClose }: { visitId: string; onClose: () => void }) => {
  const { data, loading, error } = useVisitPapaAllotmentPeriodsQuery({
    variables: { id: visitId },
  });

  const papaId = data?.visit?.data?.papa?.data?.id;
  const allotmentPeriods = data?.visit?.data?.papa?.data?.allotmentPeriods?.data;
  const businessPolicy = data?.visit?.data?.papa?.data?.currentBusinessPolicy?.data;

  if (loading) return <Loader isLoading />;

  if (!papaId || !allotmentPeriods || !businessPolicy || error) {
    return (
      <Feedback isInvalid>
        Unable to create a credit. {error?.message ? error?.message : null}
      </Feedback>
    );
  }

  return (
    <Loader isLoading={loading}>
      <AddCredit
        papaId={papaId}
        allotmentPeriods={allotmentPeriods}
        businessPolicy={businessPolicy}
        visitId={visitId}
        onClose={onClose}
      />
    </Loader>
  );
};

export default Actions;
