import { FetchResult, useApolloClient, useQuery } from "@apollo/client";
import { AccessTime } from "@styled-icons/material-twotone/AccessTime";
import { Form, Formik } from "formik";
import debounce from "lodash/debounce";
import omit from "lodash/omit";
import pick from "lodash/pick";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";

import { SpinningIcon } from "components/CustomIcon/Spinning";
import { FormikValueObserver } from "components/FormikValueObserver";
import { useTopBarOptions } from "components/MainContainer/useTopBarOptions";
import config from "config";
import { DEFAULT_TIMEZONE } from "constants/date";
import { RECOMMENDED_TIME_SELECTED } from "constants/visit";
import {
  NonRecommendedTimeReason,
  RescheduleVisitMutation,
  UpdateScheduledVisitMutation,
  VisitDiscountType,
  VisitFlagName,
  VisitMobilityNeeds,
  VisitRescheduleInput,
  VisitSingleResult,
  useLocationLeadHoursLazyQuery,
  useRescheduleVisitMutation,
  useUpdateScheduledVisitMutation,
} from "generated/types";
import { useModalToggle } from "hooks/useModalToggle";
import HourAllotmentReached from "pages/ScheduleVisit/HourAllotmentReachedModal";
import PapaCovidVaccinationModal from "pages/ScheduleVisit/PapaCovidVaccinationModal";
import { VisitSteps } from "pages/ScheduleVisit/Steps";
import {
  VisitErrorMessage,
  cannotChangeTransportationVisitTypeErrorMessage,
  defaultDiscount,
  getTasksWithNoObjectives,
  mapSubmitFormValues,
  maxScheduledVisitsPerDayReachedErrorMessage,
  operatingHoursErrorMessage,
} from "pages/ScheduleVisit/constants";
import { setDeliveryBasedOnTasks } from "pages/ScheduleVisit/shared/DeliveryVisitHelpers";
import { SystemNotesInput, Values } from "pages/ScheduleVisit/types";
import { nonNull, nonNullWithId } from "utils/nonNull";

import { mergeTimezoneShiftedDateAndTime } from "../../../utils/date";
import { NoObjectiveConfirmModal } from "../NoObjectiveConfirmModal";
import { PreferredPalCancelWarningModal } from "../PreferredPalCancelWarningModal";
import { SYSTEM_NOTES_QUERY } from "../gql";
import RescheduleVisitRequestModal from "./RescheduleVisitRequestModal";
import VisitLeadTimeExceptionModal from "./VisitLeadTimeExceptionModal";
import { VISIT_QUERY } from "./gql";
import { visitSchema } from "./schema";

const EditVisit: React.FC = () => {
  /**
   ** BROWSER HISTORY
   */
  const history = useHistory();

  const client = useApolloClient();

  /**
   ** URL PARAMETER CACHING
   */
  const { visitId } = useParams<{ visitId?: string }>();

  /**
   ** ASYNC GQL QUERIES FOR COMPONENT DATA
   */
  const { data, loading } = useQuery<{
    visit?: VisitSingleResult | null;
  }>(VISIT_QUERY, {
    variables: { id: visitId },
  });

  const [getLocationLeadHours] = useLocationLeadHoursLazyQuery();

  /**
   ** FORM SUBMISSION GQL MUTATIONS
   */
  const [updateVisit, { loading: updateVisitmutationLoading }] = useUpdateScheduledVisitMutation();
  const [rescheduleVisit, { loading: rescheduleVisitmutationLoading }] =
    useRescheduleVisitMutation();

  /**
   ** FORMIK VALUE STATE
   */
  const [visitFormValues, setVisitFormValues] = useState<Values>();

  /**
   ** MODAL STATE
   */
  const { isOpen: hourAllotmentReachedModalOpen, toggle: toggleHourAllotmentReachedModal } =
    useModalToggle();

  const { isOpen: papaCovidVaccinationModalOpen, toggle: togglePapaCovidVaccinationModal } =
    useModalToggle();

  const {
    isOpen: preferredPalCancelWarningModalOpen,
    toggle: togglePreferredPalCancelWarningModal,
  } = useModalToggle();

  const { isOpen: noObjectiveConfirmModalOpen, toggle: toggleNoObjectiveConfirmModal } =
    useModalToggle();

  const { isOpen: demandProfilesLeadTimeModalOpen, toggle: toggleDemandProfilesLeadTimeModal } =
    useModalToggle();

  const { isOpen: rescheduleVisitRequestModalOpen, toggle: toggleRescheduleVisitRequestModal } =
    useModalToggle();

  /**
   ** COMPONENT STATE
   */
  const [taskIdsWithNoObjectives, setTaskIdsWithNoObjectives] = useState<string[]>([]);

  const [earliestAllowedDates, setEarliestAllowedDates] = useState<{
    VvDate: string;
    IpvDate: string;
  }>();

  /**
   ** COMPONENT CALLBACKS
   */

  // Wrapper for setVisitFormValues that also updates the delivery flag based
  // on the tasks, when appropriate.
  const updateVisitFormValues = (values: Values) => {
    setDeliveryBasedOnTasks(values);
    setVisitFormValues(values);
  };

  const handleLocationChange = async (locationId: string) => {
    const { data } = await getLocationLeadHours({ variables: { locationId: locationId } });
    const earliestAllowedVvDate = data?.location?.data?.earliestAllowedVvDate;
    const earliestAllowedIpvDate = data?.location?.data?.earliestAllowedIpvDate;

    setEarliestAllowedDates({ VvDate: earliestAllowedVvDate, IpvDate: earliestAllowedIpvDate });
  };

  const shouldReschedule = (values: Values) => {
    return (
      values.selectedRecommendedDate !== initialValues.selectedRecommendedDate ||
      values.scheduledForDay !== initialValues.scheduledForDay ||
      values.scheduledForTime !== initialValues.scheduledForTime
    );
  };

  const createRescheduleVisitRequest = async (values: Values) => {
    if (shouldReschedule(values)) {
      updateVisitFormValues(values);
      toggleRescheduleVisitRequestModal();
    } else {
      await checkForPreferredPalCancellationWarning(values);
    }
  };

  const checkForPreferredPalCancellationWarning = async (values: Values) => {
    setVisitFormValues(values);

    if (values.hasDoctorVisitsTask && (values.palId || values.favoritePalsOnly)) {
      togglePreferredPalCancelWarningModal();
    } else {
      await taskObjectiveConfirm(values);
    }
  };

  const taskObjectiveConfirm = async (values: Values) => {
    const taskIds = getTasksWithNoObjectives(values);
    if (taskIds.length > 0) {
      setTaskIdsWithNoObjectives(taskIds);
      updateVisitFormValues(values);
      toggleNoObjectiveConfirmModal();
    } else {
      await createVisitLeadTimeException(values);
    }
  };

  const createVisitLeadTimeException = async (values: Values) => {
    if (shouldReschedule(values) === false) {
      return await handleSubmit(values);
    }

    const selectedDate = !!values.selectedRecommendedDate
      ? values.selectedRecommendedDate
      : values.scheduledForDay;

    const earliestAllowedDate = values?.isVirtual
      ? earliestAllowedDates?.VvDate
      : earliestAllowedDates?.IpvDate;

    const selectedDateTooEarly =
      moment(selectedDate).diff(moment(earliestAllowedDate), "hours") < 0;

    if (selectedDateTooEarly) {
      toggleDemandProfilesLeadTimeModal();
    } else {
      await handleSubmit(values);
    }
  };

  const toastSuccessAndPushHistory = (msg: string, visitId: string) => {
    toast.success(msg);
    history.push(`/visits/${visitId}`);
  };

  const performRescheduleVisit = async ({
    selectedRecommendedDate,
    scheduledForDay,
    scheduledForTime,
    overrideBusinessPolicy,
    obpReasonDescription,
    nonRecommendedTimeReason,
    overrideVisitLeadTime,
    overrideVisitLeadTimeReason,
    overrideVisitLeadTimeAdditionalDetails,
    rescheduleRequestReason,
    applyToFutureRecurrences,
  }: Values) => {
    const input: VisitRescheduleInput = {
      scheduledFor: mergeTimezoneShiftedDateAndTime({
        day: selectedRecommendedDate || scheduledForDay,
        time: scheduledForTime,
        timezone,
      }),
      applyToFutureRecurrences: applyToFutureRecurrences || false,
      reason: rescheduleRequestReason!,
      overrideBusinessPolicy,
      obpReason: overrideBusinessPolicy
        ? { code: "OVERRIDE_BUSINESS_POLICY", description: obpReasonDescription }
        : undefined,
      nonRecommendedTimeReason:
        nonRecommendedTimeReason === RECOMMENDED_TIME_SELECTED
          ? null
          : (nonRecommendedTimeReason as NonRecommendedTimeReason),
      overrideVisitLeadTime,
      visitFlags: [
        ...(overrideVisitLeadTime
          ? [
              {
                name: VisitFlagName.VisitLeadTimeException,
                reason: overrideVisitLeadTimeReason!,
                description: overrideVisitLeadTimeAdditionalDetails,
                visitId,
              },
            ]
          : []),
      ],
    };

    return rescheduleVisit({
      variables: { id: visitId!, input },
    });
  };

  const handleMutationsResponses = (
    data: UpdateScheduledVisitMutation | null | undefined,
    rescheduleVisitResponse: FetchResult<RescheduleVisitMutation> | undefined,
    rescheduled: boolean,
    applyToFutureRecurrences: boolean
  ) => {
    if (
      rescheduled &&
      applyToFutureRecurrences &&
      !!data?.updateVisit?.data?.id &&
      !!rescheduleVisitResponse?.data?.rescheduleVisit?.data?.id
    ) {
      toastSuccessAndPushHistory(
        "The visit and its future recurrences were edited and rescheduled with success!",
        data.updateVisit.data.id
      );
    } else if (
      rescheduled &&
      !applyToFutureRecurrences &&
      !!data?.updateVisit?.data?.id &&
      !!rescheduleVisitResponse?.data?.rescheduleVisit?.data?.id
    ) {
      toastSuccessAndPushHistory(
        "The visit was edited and rescheduled with success!",
        data.updateVisit.data.id
      );
    } else if (
      !applyToFutureRecurrences &&
      !rescheduled &&
      !!data?.updateVisit?.data?.id &&
      !rescheduleVisitResponse?.data?.rescheduleVisit?.data?.id
    ) {
      toastSuccessAndPushHistory("The visit was edited with success!", data.updateVisit.data.id);
    } else if (
      applyToFutureRecurrences &&
      !rescheduled &&
      !!data?.updateVisit?.data?.id &&
      !rescheduleVisitResponse?.data?.rescheduleVisit?.data?.id
    ) {
      toastSuccessAndPushHistory(
        "The visit and its future recurrences were edited with success!",
        data.updateVisit.data.id
      );
    } else {
      throw new Error("Something is wrong");
    }
  };

  const performUpdateVisit = async (values: Values, rescheduled: boolean) => {
    let updateVisitResposne: FetchResult<UpdateScheduledVisitMutation> = {};
    try {
      updateVisitResposne = await updateVisit({
        ...buildUpdateVisitInputVars(values),
      });
    } catch (error) {
      if (
        config.featureFlag.businessPolicy &&
        (error as Error).message === VisitErrorMessage.hourAllotmentReached
      ) {
        toggleHourAllotmentReachedModal();
      } else if (
        config.featureFlag.businessPolicy &&
        (error as Error).message === VisitErrorMessage.papaCovidVaccination
      ) {
        togglePapaCovidVaccinationModal();
      } else if ((error as Error).message === VisitErrorMessage.scheduledOutsideBusinessHours) {
        window.console.error(operatingHoursErrorMessage);
        toast.error(operatingHoursErrorMessage);
      } else if (
        (error as Error).message === VisitErrorMessage.cannot_change_transportation_visit_type
      ) {
        window.console.error(cannotChangeTransportationVisitTypeErrorMessage);
        toast.error(cannotChangeTransportationVisitTypeErrorMessage);
      } else if (
        config.featureFlag.businessPolicy &&
        (error as Error).message === VisitErrorMessage.max_scheduled_visits_per_day_reached
      ) {
        toast.error(maxScheduledVisitsPerDayReachedErrorMessage);
      } else {
        window.console.error(error);
        toast.error((error as Error).message);
      }

      return;
    }

    let rescheduleVisitResponse: FetchResult<RescheduleVisitMutation> | undefined;
    try {
      if (rescheduled) {
        rescheduleVisitResponse = await performRescheduleVisit(values);
      }
    } catch (error) {
      if ((error as Error).message === VisitErrorMessage.scheduledOutsideBusinessHours) {
        window.console.error(operatingHoursErrorMessage);
        toast.error(operatingHoursErrorMessage);
      } else if (
        config.featureFlag.businessPolicy &&
        (error as Error).message === VisitErrorMessage.max_scheduled_visits_per_day_reached
      ) {
        toast.error(maxScheduledVisitsPerDayReachedErrorMessage);
      } else {
        window.console.error(error);
        toast.error((error as Error).message);
      }

      return;
    }

    handleMutationsResponses(
      updateVisitResposne.data,
      rescheduleVisitResponse,
      rescheduled,
      values.applyToFutureRecurrences || false
    );
  };

  const handleSubmit = async (values: Values, applyToFutureRecurrences?: boolean) => {
    if (!visitId) return;

    performUpdateVisit(values, shouldReschedule(values));
  };

  const visit = data?.visit?.data ?? {};

  const buildUpdateVisitInputVars = (values: Values) => {
    const input = {
      ...omit(mapSubmitFormValues(values), ["scheduledFor", "accountId", "papaId", "papaName"]),
    };

    const temporaryTypesOverwrite = () => {
      const discount = {
        ...input.discount,
        type: input.discount.type as VisitDiscountType,
      };
      const nonRecommendedTimeReason = input.nonRecommendedTimeReason as NonRecommendedTimeReason;

      return {
        ...omit(input, ["discount", "nonRecommendedTimeReason"]),
        discount,
        ...(nonRecommendedTimeReason ? { nonRecommendedTimeReason } : {}),
      };
    };

    const overwrites = temporaryTypesOverwrite();

    return {
      variables: {
        ...overwrites,
        id: visitId!,
        visitFlags: visit.visitFlags,
      },
    };
  };

  const getSystemNotesForVisit = async (systemNotesInput: SystemNotesInput) => {
    try {
      const input = {
        payorBusinessId: systemNotesInput.businessId,
        taskIds: systemNotesInput.taskIds,
        mobilityNeeds: systemNotesInput.mobilityNeeds,
      };
      const { data } = await client.query({
        query: SYSTEM_NOTES_QUERY,
        variables: { input: input },
      });

      if (data?.systemNotesForVisitHtml.data.length) {
        return data.systemNotesForVisitHtml.data;
      }
    } catch (error) {
      return [];
    }
  };

  const debouncedHandleValuesChange = useRef(
    debounce(async (values: Values, initialValues: Values) => {
      const systemNotesInput: SystemNotesInput = pick(values, [
        "taskIds",
        "businessId",
        "mobilityNeeds",
      ]);
      const systemNotes = await getSystemNotesForVisit(systemNotesInput);
      values.systemNotes = systemNotes;
      setVisitFormValues(values);
    }, 500)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleValuesChange.cancel();
    };
  }, [debouncedHandleValuesChange]);

  /**
   ** COMPONENT LOGIC AND PRESENTATION
   */

  if (!visitId) {
    history.push("/schedule-visit");
  }

  const papaFullName = data?.visit?.data?.papa?.data?.fullName;
  useTopBarOptions({
    title: "Edit Visit",
    name: papaFullName ?? "",
    icon: AccessTime,
    breadcrumbs: ["Visits"],
    deps: [papaFullName],
  });

  if (loading) return <SpinningIcon size={28} />;

  if (!visit) {
    history.push("/schedule-visit");

    return null;
  }

  const visitDiscount = visit.discount?.data;
  const isFixedDiscount = visitDiscount?.type === VisitDiscountType.FixedAmount;
  const discount = visitDiscount
    ? {
        type: visitDiscount.type ?? defaultDiscount.type,
        valueFixed: isFixedDiscount ? visitDiscount.value ?? defaultDiscount.valueFixed : 0,
        valuePercentage: isFixedDiscount
          ? 0
          : visitDiscount.value ?? defaultDiscount.valuePercentage,
      }
    : defaultDiscount;

  const timezone = visit.location?.data?.timezone;
  const location = visit.location?.data;
  const destination = visit.destination?.data;

  const additionalLocations = nonNull(visit?.additionalLocations?.data).map(
    (additionalLocation) => ({
      id: additionalLocation.id ?? "",
      description: additionalLocation.description ?? "",
      location: additionalLocation,
      stayWithMember: additionalLocation.stayWithMember
        ? "stay"
        : additionalLocation.dropOff
        ? "drop"
        : null,
    })
  );

  const initialValues: Values = {
    accountId: visit.account?.data?.id || "",
    accountType: visit.account?.data?.type || "",
    additionalLocations,
    businessId: "",
    businessPolicyEnd: "",
    byDay: visit.recurrence?.data?.config?.byDay ?? null,
    cardId: visit.card?.data?.id || "",
    count: visit.recurrence?.data?.config?.count ?? null,
    delivery: visit.delivery,
    destination,
    discount,
    endOnDate: !visit?.recurrence?.data?.config?.count,
    estimatedDuration: visit.estimatedDuration ?? undefined,
    favoritePalsOnly: visit?.favoritePalsOnly ?? false,
    freq: visit.recurrence?.data?.config?.freq ?? null,
    hasDoctorVisitsTask: false,
    hasGroceryTask: false,
    hasMedicationTask: false,
    hasRelocationTasks: false,
    hasUberTasks: false,
    hasOvernightTransportationTasks: false,
    invitePalIds: nonNullWithId(visit.invitations?.data).map((p) => p.id),
    isPaymentValid: false,
    isUberEligible: visit.isUberEligible ?? null,
    acceptsThirdPartyTransportation: visit.isUberEligible ? true : null,
    isVirtual: !!visit.isVirtual,
    location: location || null,
    memberNote: visit.memberNote || "",
    mobilityNeeds: visit.mobilityNeeds || VisitMobilityNeeds.None,
    note: visit.note || "",
    objectives: nonNullWithId(visit.objectives?.data).map((objective) => ({
      ...objective,
      label: objective.description as string,
      value: objective.id,
    })),
    overrideBusinessPolicy: false,
    obpReasonDescription: "",
    papaId: visit.papa?.data?.id || "",
    scheduledForDay:
      moment.tz(visit.scheduledFor, timezone || DEFAULT_TIMEZONE).toISOString() || "",
    scheduledForTime:
      moment.tz(visit.scheduledFor, timezone || DEFAULT_TIMEZONE).format("LT") || "",
    selectedRecommendedDate: "",
    surveyIds: nonNull(visit.surveyResponses?.data).map(({ survey }) => survey?.data?.id ?? ""),
    systemNotes: visit.systemNotesHtml || [],
    taskIds: nonNullWithId(visit.tasks?.data).map(({ id }) => id),
    validUntil: visit.recurrence?.data?.validUntil ?? null,
    visibleToPalApp: !!visit.visibleToPalApp,
    overrideVisitLeadTime: false,
    overrideVisitLeadTimeReason: null,
    overrideVisitLeadTimeAdditionalDetails: "",
    applyToFutureRecurrences: null,
    validateAcceptsThirdPartyTransportation: false,
  };

  return (
    <>
      <Formik<Values>
        initialValues={initialValues}
        validationSchema={visitSchema}
        onSubmit={createRescheduleVisitRequest}
      >
        {({ submitForm }) => (
          <Form>
            <FormikValueObserver onChange={debouncedHandleValuesChange} />
            <VisitSteps
              isEditMode
              isPending={visit.state === "PENDING"}
              isDemandProfilesLeadTimeModalOpen={demandProfilesLeadTimeModalOpen}
              toggleDemandProfilesLeadTimeModal={toggleDemandProfilesLeadTimeModal}
              onDemandProfilesLeadTimeModal={handleSubmit}
              visitFormValues={visitFormValues}
              handleLocationChange={handleLocationChange}
              earliestAllowedDates={earliestAllowedDates}
              isCloneMode={false}
            />

            <HourAllotmentReached
              isOpen={hourAllotmentReachedModalOpen}
              toggle={toggleHourAllotmentReachedModal}
            />

            <PapaCovidVaccinationModal
              isOpen={papaCovidVaccinationModalOpen}
              toggle={togglePapaCovidVaccinationModal}
              onProceed={submitForm}
            />
          </Form>
        )}
      </Formik>
      <PreferredPalCancelWarningModal
        isOpen={preferredPalCancelWarningModalOpen}
        visitFormValues={visitFormValues}
        onSubmit={taskObjectiveConfirm}
        toggle={togglePreferredPalCancelWarningModal}
      />
      <NoObjectiveConfirmModal
        isOpen={noObjectiveConfirmModalOpen}
        loading={rescheduleVisitmutationLoading || updateVisitmutationLoading}
        visitFormValues={visitFormValues}
        taskIdsWithNoObjectives={taskIdsWithNoObjectives}
        onSubmit={createVisitLeadTimeException}
        toggle={toggleNoObjectiveConfirmModal}
      />
      <RescheduleVisitRequestModal
        isOpen={rescheduleVisitRequestModalOpen}
        toggle={toggleRescheduleVisitRequestModal}
        visitFormValues={visitFormValues}
        onSubmit={taskObjectiveConfirm}
      />
      <VisitLeadTimeExceptionModal
        isOpen={demandProfilesLeadTimeModalOpen}
        toggle={toggleDemandProfilesLeadTimeModal}
        onSubmit={(formValues) => handleSubmit(formValues as Values)}
        visitFormValues={{ action: "reschedule", values: visitFormValues! }}
      />
    </>
  );
};

export default EditVisit;
