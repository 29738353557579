import { gql } from "@apollo/client";

export const BILLING_FORMULA_LIST = gql`
  query BillingFormulas {
    billingFormulas {
      data {
        default
        description
        id
        name
      }
    }
  }
`;

export const CREATE_BUSINESS_POLICY = gql`
  mutation createBusinessPolicy($input: BusinessPolicyInput!) {
    createBusinessPolicy(input: $input) {
      data {
        id
      }
    }
  }
`;

export const CREATE_MAX_TIME_RULE = gql`
  mutation createMaxTimeRule($input: MaxTimeRuleInput) {
    createMaxTimeRule(input: $input) {
      data {
        id
        businessPolicy {
          data {
            id
          }
        }
      }
    }
  }
`;

export const GET_CURRENT_BUSINESS_POLICY = gql`
  query getCurrentBusinessPolicy($accountId: ID!) {
    account(id: $accountId) {
      data {
        businessPolicies {
          data {
            id
            startsAt
            endsAt
            billingFormula {
              data {
                default
                description
                id
                name
              }
            }
          }
        }
        currentBusinessPolicy {
          data {
            id
            allowInPersonVisits
            allowVirtualVisits
            billingFormula {
              data {
                default
                description
                id
                name
              }
            }
            endsAt
            inPersonDisallowedTasks {
              data {
                id
              }
            }
            lonelinessCompliant
            palCovidVaccination
            papaCovidVaccination
            allowUberForTransportation
            careConciergeEnabled
            paysForCareConcierge
            maxMinutesPerVisit
            maxVisitDistanceMi
            maxScheduledVisitsPerDay
            warmTransfer
            warmTransferPhoneNumber
            maxTimeRules {
              data {
                numberOfMinutes
              }
            }
            startsAt
            virtualDisallowedTasks {
              data {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_BUSINESS_POLICY_UNLIMITED_MINUTES = gql`
  query RootQuery {
    businessPolicyUnlimitedMinutesAmount
  }
`;
